import React from 'react';
import RoomOverview from 'components/room-overview/room-overview';
import appConfig from 'config/app.config';
import {getCookie, deleteCookie, setCookie} from 'helpers/cookie-helper';
import RoomOverviewController from 'components/room-overview/room-overview-controller';
import RoomController from 'components/room/room-controller';
import TrophyRoom from 'components/trophy-room/trophy-room';
import { resetRoomCompletion, resetRoomChallengeData } from 'helpers/room-data-helper';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import PropTypes from 'prop-types';

const gamePages = {
	trophyRoom: {component: TrophyRoom},
	roomOverview: {component: RoomOverviewController},
	room: {component: RoomController},
};

class GameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			pageId: 'roomOverview',
			roomId: null,
			playerData: null,
			cookieConsent: false,
			hasAnsweredConsent: false,
			triggerFirstTimeConsent: false,
		};

		this.defaultPlayerData = {
			challenges: [],
		};
	}

	/* Component mounted */
	componentDidMount = () => {
		getCookie(appConfig.playerDataCookieName).then((response) => {
			/* Load player data if available */
			let playerData = JSON.parse(JSON.stringify(this.defaultPlayerData));
			if (response && response.length > 0) {
				playerData = Object.assign({}, {...playerData, ...JSON.parse(response)});
			}

			/* Update state */
			this.setState({
				hasAnsweredConsent: response.length > 0,
				cookieConsent: response.length > 0,
				isLoading: false, 
				playerData
			});
		});
	}

	/**
	 * Reset player data
	 */
	resetPlayerData = () => {
		deleteCookie(appConfig.playerDataCookieName);
		window.location.reload();
	}

	/**
	 * Resets data associated with the current room id
	 * @param {number} roomId 
	 */
	resetRoomData = () => {
		const roomCompletion = resetRoomCompletion(this.state.playerData, true, this.state.roomId);
		const challenges = resetRoomChallengeData(this.state.roomId, this.state.playerData);

		this.updatePlayerData({
			challenges: challenges, 
			roomCompletion: roomCompletion,
		}).then((response) => {
			if (response.status === 'ok') {
				this.goToPage('room', this.state.roomId);
			}
		});
	}

	/**
	 * Go to page
	 * @param {string} pageId 
	 * @param {string} roomId 
	 */
	goToPage = (pageId, roomId = null) => {
		if (pageId === 'room' && roomId === 1 && !this.state.hasAnsweredConsent) {
			this.setState({triggerFirstTimeConsent: true});
			return;
		}

		/* Scroll to top */
		window.scrollTo(0, 0);
		/* Update state */
		this.setState({pageId, roomId});
	}


	/**
	 * Update player data
	 * @param {object} updates
	 */
	updatePlayerData = (updates) => {
		return new Promise((resolve)=>{
			const newPlayerDataObj = Object.assign({}, {...this.state.playerData, ...updates});
			if (this.state.cookieConsent) {
				setCookie(appConfig.playerDataCookieName, JSON.stringify(newPlayerDataObj));
			}
			this.setState({playerData: newPlayerDataObj}, () => {
				resolve({ status: 'ok' });
			});
		});
	}

	/**
	 * Accepts or declines cookies depending on the given boolean
	 * @param {bool} cookiesAccepted 
	 */
	setCookiesConsent = (cookiesAccepted) => {
		if (cookiesAccepted) {
			setCookie(appConfig.playerDataCookieName, JSON.stringify(this.state.playerData));
		} else {
			deleteCookie(appConfig.playerDataCookieName);
		}
		return new Promise((resolve) => {
			this.setState({
				cookieConsent: cookiesAccepted,
				hasAnsweredConsent: true,
			}, () => { 
				resolve({ status: 'ok' });
			});
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		let triggerFirstTime = this.state.triggerFirstTimeConsent && !this.state.hasAnsweredConsent;
		let Component = RoomOverview;	
		if (gamePages.hasOwnProperty(this.state.pageId)) Component = gamePages[this.state.pageId].component;
		if (this.state.playerData) {
			return (
				<div>
					<Component
						currentLanguageId={this.props.currentLanguageId}
						isLoading={this.state.isLoading}
						playerData={this.state.playerData}
						roomId={this.state.roomId}
						goToPage={this.goToPage} 
						updatePlayerData={this.updatePlayerData}
						resetPlayerData ={this.resetPlayerData}
						resetRoomData={this.resetRoomData}
						setCurrentLanguageId={this.props.setCurrentLanguageId}
					/>
					<CookieConsentController 
						currentLanguageId={this.props.currentLanguageId}
						setCookieConsent={this.setCookiesConsent}
						cookieConsentState={this.state.cookieConsent}
						triggerFirstTimeCookieConsent={triggerFirstTime}
					/>
				</div>
			);
		}
		return null;
	}
}

GameController.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default GameController;