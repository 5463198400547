import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {shuffleArray} from 'helpers/array-helper';
import {quizPoints, streakPoints} from 'data/points-data';
import Quiz from './quiz';

class QuizController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPaused: false,
			playerHasSeenClueWarning: false,
			showConfirmGetCluePopup: false,
			showImagePopup: false,
			quizData: null,
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load challenge */
		this.loadChallenge();
	}

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.challengeData.id !== prevProps.challengeData.id) {
			this.setState({isLoading: true, quizData: null}, () => {this.loadChallenge();});
		} 
	}

	/**
	 * Load quiz & sync with player data
	 */
	loadChallenge = () => {
		/* Get quiz data */
		let challengeData = JSON.parse(JSON.stringify(this.props.challengeData));
		challengeData.completed = false;

		/* Shuffle answers */
		challengeData.answers = shuffleArray(challengeData.answers);

		/* Sync with player progress */
		if (
			this.props.playerData.hasOwnProperty('challenges') &&
				this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				challengeData.completed = playerChallengeData.completed;
			}
			challengeData.answers.forEach((answer) => {
				if (playerChallengeData.selectedAnswers.indexOf(answer.id) >= 0) answer.isSelected = true;
			});		
		}

		/* Update state */
		this.setState({isLoading: false, isPaused: challengeData.completed, quizData: challengeData});
	}

	/**
	 * Show / hide clue popup
	 * @param {bool} showConfirmGetCluePopup
	 */
	toggleCluePopup = (showConfirmGetCluePopup) => {
		this.setState({showConfirmGetCluePopup: showConfirmGetCluePopup});
	}

	/**
	 * Show / hide image popup
	 */
	toggleImagePopup = (showImagePopup) => {
		this.setState({showImagePopup: showImagePopup});
	}

	/**
	 * Get next clue
	 */
	handleGetClue = () => {
		/* Update quiz data */
		let quizData = JSON.parse(JSON.stringify(this.state.quizData));
		let nextClueIndex = quizData.clues.findIndex((clue) => {return !clue.isRevealed;});
		quizData.clues[nextClueIndex].isRevealed = true;
				
		/* Update player data */
		let revealedClues = quizData.clues.filter((clue) => {return clue.isRevealed;}).length;
		let playerChallenges = [];
		if (this.props.playerData.hasOwnProperty('challenges')) {
			playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
		}
		let playerChallengeIndex = playerChallenges.findIndex((challenge) => {return challenge.id === quizData.id;});
		if (playerChallengeIndex === -1) {
			playerChallenges.push({id: quizData.id, completed: false, clues: revealedClues, selectedAnswers: []});
		} else {
			playerChallenges[playerChallengeIndex].clues = revealedClues;
		}
		
		this.props.updatePlayerData({challenges: playerChallenges}).then((response) => {
			if (response.status === 'ok') {
				this.setState({
					playerHasSeenClueWarning: true,
					showConfirmGetCluePopup: false,
					quizData: quizData
				}, () => {this.adjustBodyHeight();});
			} else {
				console.error('error');
			}
		});
	}

	/**
	 * Select answer
	 * @param {number} answerId
	 */
	handleSelectAnswer = (answerId) => {
		if (this.state.isPaused) return;

		let anyCorrectAnswers = false;
		
		this.setState({isPaused: true}, () => {
			/* Update quiz data */
			let quizData = JSON.parse(JSON.stringify(this.state.quizData));
			let answerIndex = quizData.answers.findIndex((answer) => {return answer.id === answerId;});
			quizData.answers[answerIndex].isSelected = true;
			this.setState({quizData: quizData});
			
			/* Player data - progress */
			let errors = 0;
			quizData.answers.forEach((answer) => {
				if (!answer.isCorrect && answer.isSelected) errors = errors + 1;
			});

			let selectedAnswers = [];
			quizData.answers.forEach((answer) => {
				if (answer.isSelected) selectedAnswers.push(answer.id);
			});

			let playerChallenges = [];
			if (this.props.playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
			}
			let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
				return challenge.id === quizData.id;
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: quizData.id, 
					completed: false,
					errors: errors, 
					selectedAnswers: selectedAnswers, 
					roomId: this.props.roomId
				});
			} else {
				playerChallenges[playerChallengeIndex].roomId = this.props.roomId;
				playerChallenges[playerChallengeIndex].selectedAnswers = selectedAnswers;
				playerChallenges[playerChallengeIndex].errors = errors;
			}

			if (selectedAnswers.length > errors) {
				anyCorrectAnswers = true;
			}

			/* Player data - streaks */
			let answerIsCorrect = quizData.answers[answerIndex].isCorrect;
			let streakUnlocked = false;
			let playerStreaks = {};
			if (appConfig.useStreaks) {
				if (this.props.playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(this.props.playerData.streaks));
				}
				if (this.props.roomId !== 'bonus') {
					if (!playerStreaks.hasOwnProperty('room' + this.props.roomId.toString())) {
						playerStreaks['room' + this.props.roomId.toString()] = 0;
					}
					if (playerStreaks['room' + this.props.roomId.toString()] !== -1) {
						if (answerIsCorrect) {
							playerStreaks['room' + this.props.roomId.toString()] = 
								playerStreaks['room' + this.props.roomId.toString()] + 1;
						} else {
							playerStreaks['room' + this.props.roomId.toString()] = 0;	
						}
						let roomStreak = parseInt(playerStreaks['room' + this.props.roomId.toString()]);
						if (roomStreak === streakPoints.streakMarker) {
							streakUnlocked = true;
							playerStreaks['room' + this.props.roomId.toString()] = -1;
						}
					}
				} 
			}

			/* Update player data */
			this.props.updatePlayerData({
				challenges: playerChallenges,
			}).then((response) => {
				if (response.status === 'ok') {
					/* Check if all correct answers have been selected */
					let challengeIsComplete = 
					quizData.answers.filter((answer) => {
						return (answer.isCorrect && answer.isSelected);
					}).length ===
					quizData.answers.filter((answer) => {return answer.isCorrect;}).length;

					if (challengeIsComplete) {
						/* Update game state, handle challenge completed */
						this.timeout = setTimeout(() => {this.handleCompleteQuiz(streakUnlocked);}, 250);
					} else {
						/* Update challenge data */
						let points = this.calculatePoints(quizData);
						if (!anyCorrectAnswers && !answerIsCorrect) {
							points = 0;
						}
						this.props.updateChallengeData(
							quizData.id,
							points
						);
						
						/* Update game state, show streak popup */
						this.setState({quizData: quizData, isPaused: false});
						if (streakUnlocked) {
							this.timeout = setTimeout(() => {this.props.toggleStreakPopup(true);}, 250);
						}
					}	
				}
			});
		});
	}

	/**
	 * Calculates points for challenge
	 * Calculates points based on quizData and amount of wrong answers
	 * @param {*} quizData
	 * @returns 
	 */
	calculatePoints = (quizData) => {
		let basePoints = quizPoints.basePoints;

		let wrongAnswers = 0;
		quizData.answers.forEach((answer) => {
			if (!answer.isCorrect && answer.isSelected) wrongAnswers = wrongAnswers + 1;
		});

		let points = Math.max(
			quizPoints.minPoints, 
			basePoints - (wrongAnswers * quizPoints.minusPointsPerWrongAnswer * quizPoints.minusPointsPerClues)
		);

		return points;
	}

	/**
	 * Complete quiz
	 * Check answer(s), give feedback, update player data
	 * @param {bool} streakUnlocked
	 */
	handleCompleteQuiz = (streakUnlocked) => {
		let quizData = JSON.parse(JSON.stringify(this.state.quizData));
		quizData.completed = true;

		/* Calculate points */
		let points = this.calculatePoints(quizData);

		/* Prepare feedback */
		let feedback = null;
				
		/* Update player data */
		this.props.completeChallenge(quizData.id, points, feedback, streakUnlocked).then(() => {
			this.setState({quizData: quizData});
		});
	}


	render = () => {
		if (!this.state.isLoading && this.state.quizData) {
			return (
				<Quiz 
					isPaused={this.state.isPaused} 
					playerHasSeenClueWarning={this.state.playerHasSeenClueWarning}
					showConfirmGetCluePopup={this.state.showConfirmGetCluePopup}
					showImagePopup={this.state.showImagePopup}
					clueIndex={this.state.clueIndex}
					quizData={this.state.quizData} 
					toggleCluePopup={this.toggleCluePopup}
					toggleImagePopup={this.toggleImagePopup}
					handleGetClue={this.handleGetClue}
					handleSelectAnswer={this.handleSelectAnswer}
					challengeIndex={this.props.challengeIndex}
					currentLanguageId={this.props.currentLanguageId}
				/>
			);
		}
		return null;
	}
}

QuizController.propTypes = {
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	challengeData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	toggleStreakPopup: PropTypes.func.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateChallengeData: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default QuizController;