import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './pairs.scss';
import { getTranslation } from 'helpers/language-helper';

const Pairs = ({isPaused, pairsData, handleSelectCard, challengeIndex, currentLanguageId}) => {
	let numberOfPairsToFind = pairsData.columnA.filter((card) => {
		return (card.hasOwnProperty('correctConnectionId') && card.correctConnectionId !== null);
	}).length;
	return (
		<div className={'Pairs Pairs--' + pairsData.id + (isPaused ? ' Pairs--paused' : '')}>

			{/* Pairs header */}
			<div id="PairsHeader" className="Pairs-header">
				<div className="Pairs-headerContent">
					{/* Question */}
					<div className='Pairs-questionHeader'>
						<div className='Pairs-questionHeaderText'>
							{getUiText(currentLanguageId, 'challengeUiTexts', 'question')} 
							{challengeIndex}
						</div>
					</div>
					<div className="Pairs-question">
						{renderMarkdown(getTranslation(pairsData.question.text, currentLanguageId))}
					</div>
				</div>
			</div>

			{/* Pairs body */}
			<div id="PairsBody" className="Pairs-body">				
				{/* Instructions */}
				<div className="Pairs-instructions">
					{getUiText(currentLanguageId, 'challengeUiTexts', 'pairsInstructions')}
				</div>
				<div className='Pairs-columnWrapper'>
					{/* Column A */}
					<div id="ColumnA" className="Pairs-column Pairs-column">
						{pairsData.columnA.map((card, index) => {
							let className = 'Pairs-card';
							if (card.isSelected) {
								className = className + ' Pairs-card--selected';
							} else {
								if (card.connectedTo && !pairsData.completed) {
									let status = (card.connectedTo === card.correctConnectionId ? 'correct' : 'wrong');
									if (
										numberOfPairsToFind === 1 && 
										card.hasOwnProperty('correctConnectionId') && 
										card.correctConnectionId !== null
									) status = 'correct';
									className = className + ' Pairs-card--' + status;;
								}
							}
							if (pairsData.completed && card.hasOwnProperty('correctConnectionId')) {
								className = className + ' Pairs-card--correct';
							}

							let bgImg = null;
							if (card.image) {
								bgImg = require('../../../assets/images/' + card.image);
								className = className + ' Pairs-card--bgImg';
							}
							return (
								<div 
									key={index} 
									className={className}
									style={bgImg ? {backgroundImage: 'url(' + bgImg + ')'} : null}
									onClick={() => {if (!isPaused) handleSelectCard('columnA', index);}}
								>{renderMarkdown(getTranslation(card.text, currentLanguageId))}</div>
							);
						})}
					</div>

					<div className='Pairs-verticalLine'/>
					
					{/* Column B */}
					<div id="ColumnB" className="Pairs-column Pairs-column">
						{pairsData.columnB.map((card, index) => {
							let className = 'Pairs-card';
							if (card.isSelected) className = className + ' Pairs-card--selected';
							if (pairsData.completed && 
								pairsData.columnA.some((cardA) => {return cardA.correctConnectionId === card.id;})
							) className = className + ' Pairs-card--correct';
							if (!pairsData.completed && 
								pairsData.columnA.some((cardA) => {return cardA.connectedTo === card.id;})
							) {
								let cardA = pairsData.columnA.filter((cardA) => {
									return cardA.connectedTo === card.id;
								})[0];
								let status = (cardA.connectedTo === cardA.correctConnectionId ? 'correct' : 'wrong');
								if (
									numberOfPairsToFind === 1 && 
									pairsData.columnA.some((cardA) => {return cardA.correctConnectionId === card.id;})	
								) status = 'correct';
								className = className + ' Pairs-card--' + status;
							}
							return (
								<div 
									key={index} 
									className={className}
									onClick={() => {if (!isPaused) handleSelectCard('columnB', index);}}
								>{renderMarkdown(getTranslation(card.text, currentLanguageId))}</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

Pairs.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	pairsData: PropTypes.object.isRequired,
	handleSelectCard: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default Pairs;