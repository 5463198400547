import appConfig from 'config/app.config';

/**
 * Checks if language is supported
 * @param {string} languageId 
 * @returns 
 */
export function isLanguageSupported(languageId) {
	const supportedLanguages = appConfig.languages;

	const foundIndex = supportedLanguages.findIndex((id) => {
		return id === languageId;
	});

	return foundIndex !== -1;
};

/**
 * Gets language id from browser language, example is en-us to en
 * @param {string} browserLanguage 
 * @returns string
 */
export function getLanguageIdFromBrowserLanguage(browserLanguage) {
	return browserLanguage.slice(0, 2);
}

/**
 * Checks if translation exists and returns translation. If translation does not exists, returns with default language.
 * @param {object} textObject 
 * @param {string} languageId 
 */
export function getTranslation(textObject, languageId) {
	if (!textObject) {
		return '';
	}

	let translatedText = textObject[languageId];

	if (!translatedText) {
		translatedText = translatedText[appConfig.defaultLanguage];
	}

	return translatedText;
}