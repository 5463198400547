/* 
QUIZ TEMPLATE
{
	id: '',
	type: 'quiz',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: {da: '', en: ''},
		showNumberOfAnswers: false,
		image: null,
		showImageInPopup: false
	},
	answers: [
		{id: 1, text: {da: '', en: ''}, isSelected: false, isCorrect: false},
		{id: 2, text: {da: '', en: ''}, isSelected: false, isCorrect: false},
		{id: 3, text: {da: '', en: ''}, isSelected: false, isCorrect: false}
	],
	clues: [
		{id: 1, text: {da: '', en: ''}, isRevealed: true},
		{id: 2, text: {da: '', en: ''}, isRevealed: false},
		{id: 3, text: {da: '', en: ''}, isRevealed: false},
	],
	firstClueIsFree: true
},

SORT TEMPLATE
{
	id: '',
	type: 'sort',
	roomId: null,
	challengeNumber: null,
    hexagonImageId: null,
	question: {
		text: {da: '', en: ''}
	},
	boxes: [
		{id: '', name: {da: '', en: ''}},
		{id: '', name: {da: '', en: ''}},
	],
	items: [
		{id: '', name: {da: '', en: ''}, isPlaced: false, correctBoxId: ''},
		{id: '', name: {da: '', en: ''}, isPlaced: false, correctBoxId: ''},
		{id: '', name: {da: '', en: ''}, isPlaced: false, correctBoxId: ''},
		{id: '', name: {da: '', en: ''}, isPlaced: false, correctBoxId: ''},
		{id: '', name: {da: '', en: ''}, isPlaced: false, correctBoxId: ''}
	]
},

PAIR TEMPLATE
{
	id: '',
	type: 'pairs',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: {da: '', en: ''}
	},
	columnA: [
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
		{id: '', text: {da: '', en: ''}, image: null, isSelected: false},
	]
},
*/
const advancedData = [
	{
		id: 'advanced-1',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 27,
		hexagonImageId: 9,
		question: {
			text: {
				da: 'Camillas mor synes, at Camilla spiller for meget Candy Crush. Camilla vil gerne vise sin mor, at det ikke passer. Kan Camilla få sin spilletid at vide ved hjælp af databeskyttelsesreglerne?',
				en: 'Camilla\'s mother thinks that Camilla is playing too much Candy Crush. Camilla wants to show her mother that it is not true. Can Camilla get information about her playtime under the data protection rules?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, det er retten til berigtigelse',
					en: 'Yes, that is the right to rectification'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, det er retten til indsigt',
					en: 'Yes, that is the right of access'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Nej, det kan hun ikke',
					en: 'No, she cannot'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'advanced-2',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 28,
		hexagonImageId: 4,
		question: {
			text: {
				da: 'Josefs forældre har hængt en seddel op på køleskabet med huslige pligter, som Josef skal hjælpe med. Josefs navn står på listen. Er det imod databeskyttelsesreglerne?',
				en: 'Josef\'s parents have posted a note on the refrigerator stating the chores that Josef has to do. Josef\'s name is on the list. Is that against the data protection rules? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, reglerne gælder i private sammenhænge',
					en: 'Yes, the rules apply in private contexts.'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Nej, det er det ikke. Forældrene må gerne skrive Josefs navn på sedlen, da det er i en privat sammenhæng',
					en: 'No. His parents are allowed to write Josef’s name on the note, since it is for private purposes'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Reglerne gælder ikke i private sammenhænge',
					en: 'The rules do not apply in private contexts.'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 4, 
				text: {
					da: 'Ja, reglerne gælder altid',
					en: 'Yes, the rules always apply'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'advanced-3',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 29,
		hexagonImageId: 13,
		question: {
			text: {
				da: 'Josef finder et brev i familiens indkørsel. På brevet står naboens navn og adresse. Josef bliver vred over, at naboens oplysninger ligger frit fremme. Han vil klage til Datatilsynet. Hvad svarer de?',
				en: 'Josef finds a letter in the family\'s driveway. The letter has the name and address of the neighbour on it. Josef gets angry that the neighbour\'s information lies on the ground like that. He wants to complain to the national  Data Protection Agency. How do they respond?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Man kan kun klage, hvis oplysningerne handler om én selv',
					en: 'You can only make a complaint if the information is about yourself'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Man kan kun klage, hvis det handler om én selv eller éns egen familie',
					en: 'You can only make a complaint if the information is about yourself or your own family'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Da pakken ligger i familiens indkørsel, er det en privat sammenhæng, og derfor gælder databeskyttelsesreglerne ikke',
					en: 'As the letter is in the family\'s driveway, it is a private context and the data protection rules therefore do not apply'
				}, 
				isSelected: false,
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'advanced-4',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 30,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'På skolen er der temauge om mad. Læreren beder eleverne om at skrive i et skema, hvad de ikke må spise og hvorfor. Josef spiser ikke svinekød, fordi han er muslim, og Camilla har nøddeallergi. Hvilken slags oplysninger er det?',
				en: 'The school has a project week focusing on food. The teacher asks the children to write in a table what they cannot eat and why. Josef does not eat pork because he is a Muslim and Camilla is allergic to nuts. What kind of information is that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Helbredsoplysninger',
					en: 'Health information'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Oplysninger om religiøse forhold',
					en: 'Information about religion'
				}, 
				isSelected: false,
				isCorrect: true
			},
			{
				id: 3,
				text: {
					da: 'Kriminelle oplysninger',
					en: 'Criminal information'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 4, 
				text: {
					da: 'Følsomme oplysninger',
					en: 'Sensitive information '
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 5, 
				text: {
					da: 'Hemmelige oplysninger',
					en: 'Secret information'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'advanced-5',
		type: 'quiz',
		roomId: 3,
		challengeNumber: 31,
		hexagonImageId: 10,
		question: {
			text: {
				da: 'Camilla er på TikTok. Hun uploader en video, hvor hun og veninderne danser. Gælder reglerne om databeskyttelse?',
				en: 'Camilla is on TikTok. She uploads a video of her and her friends dancing. Do the data protection rules apply?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, fordi det er en behandling af personoplysninger',
					en: 'Yes, because it involves processing of personal data'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Nej, reglerne om databeskyttelse gælder ikke videoer',
					en: 'No, the data protection rules do not apply to videos'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'advanced-6',
		type: 'sort',
		roomId: 3,
		challengeNumber: 32,
		hexagonImageId: 4,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Josef elsker ballet. Hans yndlingsballetdanser er Valdorf Jensen, som han ser mange videoer af på YouTube. Josef har lagt mærke til, at der automatisk dukker videoer op af Valdorf Jensen. Det undrer han sig over. Er Josefs færden på nettet dækket af reglerne om databeskyttelse?',
				en: 'Josef loves ballet. His favourite ballet dancer is Valdorf Jensen and he watches many videos of him on YouTube. Josef has noticed that videos of Valdorf Jensen automatically pop up. He is wondering about that. Are Josef\'s online activities covered by the data protection rules? '
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2', 
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Nej. YouTube er ikke dækket af reglerne, fordi de ligger i USA',
					en: 'No. YouTube is not covered by the rules because the company is located in the USA'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2', 
				name: {
					da: 'Ja, fordi YouTube indsamler oplysninger om, hvad Josef kan lide at søge på',
					en: 'Yes, because YouTube collects information about what Josef likes to search for '
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '3', 
				name: {
					da: 'Nej, for YouTube er ikke en person',
					en: 'No, because YouTube is not a person'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			}
		]
	},
	{
		id: 'advanced-7',
		type: 'sort',
		roomId: 3,
		challengeNumber: 33,
		hexagonImageId: 7,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Fodboldklubben lægger billeder op af spillerne på deres klubside. Skal de altid spørge om lov?',
				en: 'The football club posts photos of the players on their homepage. Do they always have to ask for permission?'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2',
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Ja, de skal altid spørge om lov',
					en: 'Yes, they must always ask for permission'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2', 
				name: {
					da: 'Nej. Hvis de har en god grund, behøver de ikke at spørge om lov',
					en: 'No. If they have a good reason, they do not need to ask for permission'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			}
		]
	},
	{
		id: 'advanced-8',
		type: 'sort',
		roomId: 3,
		challengeNumber: 34,
		hexagonImageId: 2,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Skolefotografen har været forbi. Der er blevet taget et klassebillede af 5.A. Hvem er de registrerede?',
				en: 'The school photographer came by. A class photo was taken of 5.A. Who are the data subjects?'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2',
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Kun lærerne, for de er voksne',
					en: 'Only the teachers because they are adults'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2',
				name: {
					da: 'Alle børn på billedet',
					en: 'All children in the photo'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '3', 
				name: {
					da: 'Alle på billedet',
					en: 'Everyone in the photo'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '4', 
				name: {
					da: 'Skolefotografen',
					en: 'The school photographer'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			}
		]
	},
	{
		id: 'advanced-9',
		type: 'sort',
		roomId: 3,
		challengeNumber: 35,
		hexagonImageId: 14,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Camilla elsker frøer. På internettet står der, at frøer i Danmark kan blive 1 meter lange og veje op til 100 kg. Det ved hun er forkert. Hvilken rettighed kan Camilla gøre brug af?',
				en: 'Camilla loves frogs. On the Internet, Camilla reads that frogs can become up to 1 metre long and weigh up to 100 kg. She knows that it is wrong. Which right can Camilla exercise?'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2', 
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Retten til berigtigelse, fordi det er forkerte oplysninger',
					en: 'The right to rectification because the information is wrong'
				},
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2', 
				name: {
					da: 'Retten til sletning, fordi det er forkerte oplysniger',
					en: 'The right to erasure because the information is wrong'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '3', 
				name: {
					da: 'Reglerne om databeskyttelse gælder ikke, fordi det ikke er en personoplysning',
					en: 'The data protection rules do not apply because it is not personal data'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			}
		]
	},
	{
		id: 'advanced-10',
		type: 'sort',
		roomId: 3,
		challengeNumber: 36,
		hexagonImageId: 2,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Camilla og Josef spiller Pokemon Go. Der popper et spørgsmål op på skærmen: "Må vi bruge din lokation?" Josef og Camilla giver tilladelse. Er lokationsregistrering en personoplysning?',
				en: 'Camilla and Josef are playing Pokemon Go. A question pops up on the screen: "May we use your location?" Josef and Camilla give permission. Is location registration personal data?'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2', 
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Ja, når det kan henføres til en person',
					en: 'Yes, when it can be attributed to a person'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '2', 
				name: {
					da: 'Nej, for lokation er ikke en personoplysning',
					en: 'No, because location is not personal data'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '3', 
				name: {
					da: 'Ja, for alt man giver tilladelse til er en personoplysning',
					en: 'Yes, because everything you give permission to is personal data'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			}
		]
	},
	{
		id: 'advanced-11',
		type: 'sort',
		roomId: 3,
		challengeNumber: 37,
		hexagonImageId: 2,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Camillas storesøster Siska har oprettet en konto på Snapchat. Snapchat anmoder om tilladelse til at samle oplysninger om Siskas lokation, for at give hende en bedre oplevelse. De indsamler oplysningerne gennem GPS. Er det en personoplysning?',
				en: 'Camilla\'s older sister Siska has created a Snapchat account. Snapchat asks for permission to collect information about Siska\'s location to improve her experience. They collect the information via GPS. Is that personal data?'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2', 
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Nej, ikke når oplysningerne indsamles gennem GPS',
					en: 'No, not when the information is collected via GPS'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2', 
				name: {
					da: 'Ja. Det er en personoplysning, fordi oplysningen fortæller, hvor Siska befinder sig',
					en: 'Yes. It is personal data because it tells where Siska is'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '3', 
				name: {
					da: 'Ja. Personoplysninger er alle oplysninger, der kan henføres til en person',
					en: 'Yes. Personal data is any information that can be attributed to a person'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			}
		]
	},
];

export {
	advancedData
};