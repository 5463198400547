import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import './cookie-consent.scss';

const CookieConsent = ({
	currentLanguageId,
	handleClosePopup, 
	cookieConsentState, 
	openConsent, 
	showCookieConsent, 
	triggerFirstTimeCookieConsent
}) => {
	return (
		<div className='CookieConsent'>
			<div className={'CookieConsent-popup' + 
				(showCookieConsent || triggerFirstTimeCookieConsent ? ' show' : '')
			}>
				<div className='CookieConsent-textWrapper'>
					<div className='CookieConsent-textHeader'>
						{getUiText(currentLanguageId, 'cookieUiTexts', 'header')}
					</div>
					<div className='CookieConsent-textBody' dangerouslySetInnerHTML={{
						__html: getUiText(currentLanguageId, 'cookieUiTexts', 'text')
					}}/>
				</div>
				<div className='CookieConsent-buttonWrapper'>
					<div className='CookieConsent-button Ok' onClick={() => {handleClosePopup(true);}}>
						<div className='CookieConsent-buttonText'>
							{getUiText(currentLanguageId, 'cookieUiTexts', 'consentButtons').ok}
						</div>
					</div>
					<div className='CookieConsent-button Cancel' onClick={() => {handleClosePopup(false);}}>
						<div className='CookieConsent-buttonText'>
							{getUiText(currentLanguageId, 'cookieUiTexts', 'consentButtons').cancel}
						</div>
					</div>
				</div>
			</div>
			<div className='CookieConsent-consentWrapper'>
				<div className={'CookieConsent-consentToggleContainer' + (cookieConsentState ? 
					' Accepted' : '')} onClick={() => {openConsent();}}/>
				<div className='CookieConsent-consentToggleText'>
					{getUiText(currentLanguageId, 'cookieUiTexts', 'toggleText')}
				</div>
			</div>
		</div>
	);	
};

CookieConsent.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	cookieConsentState: PropTypes.bool.isRequired,
	showCookieConsent: PropTypes.bool.isRequired,
	handleClosePopup: PropTypes.func.isRequired,
	openConsent: PropTypes.func.isRequired,
	triggerFirstTimeCookieConsent: PropTypes.bool.isRequired,
};

export default CookieConsent;