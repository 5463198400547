import React from 'react';
import PropTypes from 'prop-types';
import './phone.scss';

const Phone = ({id}) => {
	let phoneIndex = 1;
	if (id !== undefined) {
		phoneIndex = id;
	}

	return (
		<div className={'Phone'}>
			<div className={'Phone-logo-' + phoneIndex}/>
		</div>
	);
};

Phone.defaultProps = {
	type: null
};

Phone.propTypes = {
	id: PropTypes.number,
};

export default Phone;