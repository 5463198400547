import React, {useState, useEffect} from 'react';
import appConfig from 'config/app.config';
import {getLanguageIdFromBrowserLanguage, isLanguageSupported} from 'helpers/language-helper';
import GameController from 'components/game/game-controller';
import ConnectionStatus from 'components/connection-status/connection-status';
import ImageLoader from 'components/image-loader/image-loader';
import './app.scss';

const App = () => {
	/* Track online status */
	const [isOnline, setIsOnline] = useState(true);

	// Check whether language information is given through url or by browser language. If not then we use default.
	let defaultLanguage = getLanguageIdFromBrowserLanguage(navigator.language);
	const params = new URLSearchParams(window.location.search);
	let urlLanguage = params.get('lang');
	
	if (urlLanguage && urlLanguage.length > 0 && isLanguageSupported(urlLanguage)) {
		defaultLanguage = urlLanguage;
	} else if (!isLanguageSupported(defaultLanguage)) {
		defaultLanguage = appConfig.defaultLanguage;
	}

	const [currentLanguageId, setCurrentLanguageId] = useState(defaultLanguage);

	/**
	 * Handle change in network status (online/offline)
	 */
	const handleNetworkChange = () => {
		if (navigator.onLine !== isOnline) setIsOnline(navigator.onLine);
	};

	/**
	 * Updates language id, and updates url search parameters
	 * @param {string} languageId 
	 */
	const handleSetLanguageId = (languageId) => {
		// Update language
		setCurrentLanguageId(languageId);

		// Update url to represent current state
		const url = new URL(window.location);
		url.searchParams.set('lang', languageId);
		window.history.pushState({}, '', url);
	};

	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Redirect */
		if (window.location.pathname.toLowerCase().includes('/was')) {
			window.location.href = appConfig.availabilityStatementLink;
		}

		/* Add network status listeners */
		window.addEventListener('online', handleNetworkChange);
		window.addEventListener('offline', handleNetworkChange);
		
		/* Component will ummount */
		return () => {
			/* Remove network status listeners */
			window.removeEventListener('online', handleNetworkChange);
			window.removeEventListener('offline', handleNetworkChange);
		};
	});

	return (
		<div className="App">
			<div className='App-wrap'>
				<GameController currentLanguageId={currentLanguageId} setCurrentLanguageId={handleSetLanguageId}/>
				<ConnectionStatus currentLanguageId={currentLanguageId} isOnline={isOnline} />
				<ImageLoader />
			</div>
		</div>
	);
};	

export default App;