const imagesData = [
	'hexagon/hexagon.svg',
	'hexagon/hexagon-empty.svg',
	'hexagon/hexagon-challenge-completed.svg',
	'hexagon/hexagon-challenge-selected.svg',
	'hexagon/hexagon-inner-1.svg',
	'hexagon/hexagon-inner-2.svg',
	'hexagon/hexagon-inner-3.svg',
	'hexagon/hexagon-inner-4.svg',
	'hexagon/hexagon-inner-5.svg',
	'hexagon/hexagon-inner-6.svg',
	'hexagon/hexagon-inner-7.svg',
	'hexagon/hexagon-inner-8.svg',
	'hexagon/hexagon-inner-9.svg',
	'hexagon/hexagon-inner-10.svg',
	'hexagon/hexagon-inner-11.svg',
	'hexagon/hexagon-inner-12.svg',
	'hexagon/hexagon-inner-13.svg',
	'hexagon/hexagon-inner-14.svg',
	'cookie/cookies-accepted.svg',
	'cookie/cookies-notAccepted.svg',
	'phone/phone-1.svg',
	'phone/phone-2.svg',
	'phone/phone-3.svg',
	'phone/phone-4.svg',
	'phone/phone-5.svg',
	'phone/phone-6.svg',
	'trophy/icon-trophy-empty.svg',
	'trophy/icon-trophy-bronze.svg',
	'trophy/icon-trophy-silver.svg',
	'trophy/icon-trophy-gold.svg',
	'trophyRoom/trophy-bronze.svg',
	'trophyRoom/trophy-silver.svg',
	'trophyRoom/trophy-gold.svg',
	'trophyRoom/trophy-confetti.svg'
];

export default imagesData;
