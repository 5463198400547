import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {roomsData} from 'data/rooms-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import {isRoomCompleted} from 'helpers/progress-helper';
import Room from './room';
import { conceptsData } from 'data/rooms/concepts-data';
import { rightsData } from 'data/rooms/rights-data';
import { advancedData } from 'data/rooms/advanced-data';
import { getPlayerSyncedRoomData } from 'helpers/room-data-helper';

class RoomController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			roomStreakUnlocked: false,
			roomData: null,
			challengesData: [],
			roomChallengesData: [],
			rooms: [],
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		this.initComponent();
	}

	/**
	 * Initialize component data.
	 * This is primarily used on component mount,
	 * but also when room is changed and a re-initialization may be necessary.
	 */
	initComponent = () => {
		/* Get room data */
		let roomData = null;
		if (roomsData.some((room) => {return room.id === this.props.roomId;})) {
			roomData = roomsData.filter((room) => {return room.id === this.props.roomId;})[0];
		}

		/* Get challenges of room */
		let challengesData = [];
		challengesData = [...conceptsData, ...rightsData, ...advancedData];
		
		let roomChallengesData = challengesData.filter((challengeData) => {
			return (
				challengeData.roomId === this.props.roomId &&
				challengeData.challengeNumber && challengeData.challengeNumber >= 0
			);
		});
		roomChallengesData = sortArrayByProperty(roomChallengesData, 'challengeNumber', 'ASC');

		/* Check if room is completed */
		if (isRoomCompleted(roomChallengesData, this.props.playerData)) {
			this.props.goToPage('trophyRoom', this.props.roomId);
		};

		/* Getting player synced rooms data */
		let rooms = getPlayerSyncedRoomData(challengesData, this.props.playerData);

		/* Update state */
		this.setState({
			roomData, challengesData, roomChallengesData, rooms: rooms, isLoading: false 
		});
	}

	/**
	 * Complete room or all rooms
	 * @param {bool} roomStreakUnlocked
	 */
	handleRoomComplete = (roomStreakUnlocked) => {
		if (this.props.roomId !== 'bonus') {
			let numberOfCompletedRooms = 0;
			roomsData.forEach((room) => {
				let roomIsCompleted = false;

				if (room.id === this.props.roomId) {
					/* Current room */
					roomIsCompleted = true;

				} else {
					/* Other room */
					let roomChallengesData = this.state.challengesData.filter((challenge) => {
						return challenge.roomId === room.id;
					});
					roomIsCompleted = isRoomCompleted(roomChallengesData, this.props.playerData);
				}
				if (roomIsCompleted) numberOfCompletedRooms = numberOfCompletedRooms + 1;			
			});


			/* Room completed */
			if (numberOfCompletedRooms <= roomsData.length) {
				this.setState({
					roomStreakUnlocked: roomStreakUnlocked
				}, () => {
					this.props.goToPage('trophyRoom', this.props.roomId);	
				});	
			/* All rooms completed */
			} else {
				this.setState({
					roomStreakUnlocked: roomStreakUnlocked
				});
			}
		} else {
			/* Bonus room completed */
			this.props.updatePlayerData({bonusCompleted: true});
		}
		window.scrollTo(0, 0);
	}

	componentDidUpdate = (prevProps) => {
		// RoomId Changed we need to update state data
		if (this.props.roomId !== prevProps.roomId) {
			this.initComponent();
		}
	}

	/**
	 * Render component
	 */
	render = () => {
		let currentRoomPoints = 0;
		this.state.roomChallengesData.forEach((challenge) => {
			let playerChallengeIndex = this.props.playerData.challenges.findIndex((challengeData) => {
				return challengeData.id === challenge.id;
			});
			if (playerChallengeIndex >= 0 && this.props.playerData.challenges[playerChallengeIndex].points != null) {
				currentRoomPoints += this.props.playerData.challenges[playerChallengeIndex].points;
			}
		});
		return (
			<Room
				currentRoomPoints={currentRoomPoints}
				roomStreakUnlocked={this.state.roomStreakUnlocked}
				roomId={this.props.roomId}
				roomData={this.state.roomData}
				roomChallengesData = {this.state.roomChallengesData}
				challengesData = {this.state.challengesData}
				playerData = {this.props.playerData}
				rooms = {this.state.rooms}
				toggleIntroPopup={this.toggleIntroPopup}
				goToPage={this.props.goToPage}
				updatePlayerData={this.props.updatePlayerData}
				handleRoomComplete={this.handleRoomComplete}
				currentLanguageId={this.props.currentLanguageId}
				setCurrentLanguageId={this.props.setCurrentLanguageId}
			/>
		);
	}
}

RoomController.propTypes = {
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	playerData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default RoomController;