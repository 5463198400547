import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RoomOverview from './room-overview';
import { conceptsData } from 'data/rooms/concepts-data';
import { rightsData } from 'data/rooms/rights-data';
import { advancedData } from 'data/rooms/advanced-data';
import { getPlayerSyncedRoomData } from 'helpers/room-data-helper';

class RoomOverviewController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userCanSelectRoom: false,
			rooms: [],
			cookieButtonState: false
		};
	}

	componentDidMount = () => {
		/* Load rooms & challenges */
		const challengesData = [...conceptsData, ...rightsData, ...advancedData];
		const rooms = getPlayerSyncedRoomData(challengesData, this.props.playerData);

		/* Update state */
		this.setState({rooms, userCanSelectRoom: true});
	}
	
	/**
	 * Render component
	 */
	render = () => {
		return (
			<RoomOverview 
				userCanSelectRoom={this.state.userCanSelectRoom}
				rooms={this.state.rooms}
				toggleIntroPopup={this.toggleIntroPopup}
				goToPage={this.props.goToPage}
				resetPlayerData={this.props.resetPlayerData}
				toggleConsent={this.toggleConsent}
				playerData={this.props.playerData}
				currentLanguageId={this.props.currentLanguageId}
				setCurrentLanguageId={this.props.setCurrentLanguageId}
			/>
		);
	}
}

RoomOverviewController.propTypes = {
	playerData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	resetPlayerData: PropTypes.func.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default RoomOverviewController;