const cookieUiTexts = {
	header: {
		da: 'Må vi sætte en cookie?',
		en: 'May we use cookies?'
	},
	text: {
		da: 'Datatilsynet vil gerne registrere oplysninger om, hvor langt du og dine holdkammerater er kommet i Datadysten.<br />' +
		'Det gør vi med en cookie.<br />' +
		'<br />' +
		'Formålet med cookien er, at du og dine holdkammerater kan afbryde spillet og senere forsætte, hvor I stoppede.<br />' +
		'Datatilsynet indsamler ikke andre oplysninger, og vi deler ikke oplysningerne om, hvor langt I er nået i spillet med andre.<br />' +
		'<br />' +
		'Hvis du og dine holdkammerater vil give lov til, at Datatilsynet må indsamle oplysninger om,<br />' +
		'hvor langt I er kommet, så skal I klikke i kassen "Ja”. Det er frivilligt. I kan også vælge at sige nej og forsætte spillet. Så klikker I i kassen: ”Nej”.<br />' +
		'<br />' +
		'I kan altid trække jeres ”ja” tilbage. Det kan I gøre ved at trykke på den lille cookieknap i venstre hjørne.<br />',
		en: 'The Danish Data Protection Agency wants to collect information about how far you and your teammates have come in the Data Challenge. For that, we use a cookie.' + 
		'<br />' +
		'<br />The purpose of the cookie is to allow you and your teammates to stop the game and pick up from where you left off at some later point. The Danish Data Protection Agency does not collect any other data and we do not share data with others about how far you have come in the game.' + 
		'<br />' + 
		'<br />Click ‘Yes’, if you and your teammates want to allow the Danish Data Protection Agency to collect information about how far you have come. It is voluntary. You can also say no and still continue the game. Then simply click ‘No’.' + 
		'<br />' + 
		'<br />You can withdraw your ‘Yes’ at any time. You do so by clicking on the small cookie button in the left corner.'
	},
	consentButtons: {
		da: {ok: 'Ja', cancel: 'Nej'},
		en: {ok: 'Yes', cancel: 'No'}
	},
	toggleText: {
		da: 'Cookie-\nindstillinger',
		en: 'Cookie \nsettings'
	}
};

const trophyRoomTexts = {
	bronze: {
		da: 'BRONZEPOKAL',
		en: 'BRONZE TROPHY'
	},
	silver: {
		da: 'SØLVPOKAL',
		en: 'SILVER TROPHY'
	},
	gold: {
		da: 'GULDPOKAL',
		en: 'GOLD TROPHY'
	},
	congratulations: {
		da: 'TILLYKKE!',
		en: 'CONGRATS!'
	},
	youGot: {
		da: 'Du har optjent en',
		en: 'You have earned a'
	},
}

const errorUiTexts = {
	offline: {
		da: 'Du er offline. Forbind til internettet for at spille.',
		en: 'You are offline. Connect to the internet to play the game.'
	},
	userExists: {
		da: 'Brugeren eksisterer allerede',
		en: 'The user already exists'
	},
	nameLength: {
		da: 'Navnet må være på 3-15 bogstaver',
		en: 'The name has contain 3-15 letters'
	},
	leadingOrTrailingSpaces: {
		da: 'Navnet må ikke starte eller slutte med et mellemrum',
		en: 'The name cannot start or end with a space'
	},
	commaNotAllowed: {
		da: 'Navnet må ikke indeholde et komma',
		en: 'The name cannot contain a comma'
	},
	connectionError: {
		da: 'Der er problemer med forbindelsen',
		en: 'There are connectivity issues'
	},
	couldNotFindTextCategory: 'Tekstkategorien kunne ikke findes.',
	translationNotFound: 'Oversættelsen kunne ikke findes'
}

const generalUiTexts = {
	play: {
		da: 'Spil',
		en: 'Play'
	},
	first: {
		da: 'først',
		en: 'first'
	},
	continue: {
		da: 'Fortsæt',
		en: 'Continue'
	},
	tryAgain: {
		da: 'Forsøg igen',
		en: 'Try again'
	},
	chooseRoom: {
		da: 'Vælg et tema for at begynde spillet',
		en: 'Choose a theme to start the game'
	},
	welcome: {
		da: 'Velkommen til',
		en: 'Welcome to'
	},
	title: {
		da: 'Datadysten',
		en: 'The Data Challenge'
	},
	yourScore: {
		da: 'Jeres score',
		en: 'Your score'
	},
	points: {
		da: 'point',
		en: 'points'
	},
	availabilityStatement: {
		da: 'Tilgængelighedserklæring',
		en: 'Availability Statement'
	}
};

const challengeUiTexts = {
	noChallenges: {
		da: 'Der er ingen opgaver i område %roomId% endnu.',
		en: 'There are no assignments in area %roomId% yet.'
	},
	chooseOne: {
		da: 'Vælg det rigtige svar:',
		en: 'Choose the right answer:'
	},
	chooseMore: {
		da: 'Vælg alle rigtige svar:',
		en: 'Choose all right answers:'
	},
	sortInstructions: {
		da: 'Vælg et udsagn og derefter om det er sandt eller falsk:',
		en: 'Choose a statement and then whether it\'s true or false:'
	},
	pairsInstructions: {
		da: 'Vælg de par, der hører sammen:',
		en: 'Choose the pairs that fit:'
	},
	question: {
		da: 'Opgave ',
		en: 'Assignment '
	},
	answers: {
		da: 'svar',
		en: 'answer'
	},
	next: {
		da: 'Næste',
		en: 'Next'
	},
	next2: {
		da: 'Videre',
		en: 'Continue'
	},
	hintTitle: {
		da: 'Hint',
		en: 'Hint'
	},
	getAHint: {
		da: 'Få et <span>hint</span>',
		en: 'Get a <span>hint</span>'
	},
	getAnotherHint: {
		da: 'Få et <span>hint</span> mere',
		en: 'Get another <span>hint</span>'
	},
	viewDrawing: {
		da: 'Se tegning',
		en: 'Show drawing'
	},
	viewImage: {
		da: 'Se billede',
		en: 'Show image'
	},
	getCluePopup: {
		da: {
			title: 'OBS!',
			text: 'Jo flere hints du får, jo færre point får du.<br />Vil du have et hint?',
			buttons: {ok: 'Ja', cancel: 'Nej'}
		},
		en: {
			title: 'OBS!',
			text: 'The more hints you get, the less points you get.<br />Do you want a hint?',
			buttons: {ok: 'Yes', cancel: 'No'}
		}
	},
	streakPopup: {
		da: {
			title: 'Sådan!',
			text: '%answers% rigtige svar i træk:',
			buttons: {ok: 'Videre'}
		},
		en: {
			title: 'Well done!',
			text: '%answers% correct answers in a row:',
			buttons: {ok: 'Continue'}
		}
	},	
	challengeCompletedPopup: {
		da: {
			text1: 'Det rigtige svar er',
			text2: 'De rigtige svar er',
			text3: 'Det sidste rigtige svar er',
			text4: 'De sidste rigtige svar er'
		},
		en: {
			text1: 'The correct answer is',
			text2: 'The correct answers are',
			text3: 'The last correct answer is',
			text4: 'The last correct answers are'
		}
	},
	roomCompletedPopup: {
		da: {
			title: 'Fuldført!',
			text: 'Der er ikke flere spørgsmål i dette område.',
			text2: 'I svarede rigtigt på alle spørgsmål i området!<br />Bonus:'
		},
		en: {
			title: 'Completed!',
			text: 'There are no more questions in this area.',
			text2: 'You answered correctly on all answers in the area!<br />Bonus:'
		}
	},
	allRoomsCompletedPopup: {
		da: {
			title: 'Score: %points%p',
			text: 'Yay, spillet er gennemført'
		},
		en: {
			title: 'Score: %points%p',
			text: 'Yay, the game is complete'
		}
	},
	bonusRoomPopup: {
		da: {
			text: 'Fuldfør bonusrunden, og kravl højere op i point',
			buttons: {next: 'Videre'}
		},
		en: {
			text: 'Complete the bonus round, and gain more points',
			buttons: {next: 'Continue'}
		}
	}
}

export{
	cookieUiTexts,
	errorUiTexts,
	generalUiTexts,
	challengeUiTexts,
	trophyRoomTexts
};