import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './sort.scss';
import { getTranslation } from 'helpers/language-helper';

const Sort = ({isPaused, selectedItemIndex, animation, sortData, 
	handleSelectItem, handleSelectBox, challengeIndex, currentLanguageId}) => {
	return (
		<div className={'Sort Sort--' + sortData.id + (isPaused ? ' Sort--paused' : '')}>

			{/* Sort header */}
			<div id="SortHeader" className="Sort-header">
				<div className="Sort-headerContent">
					{/* Question */}
					<div className='Sort-questionHeader'>
						<div className='Sort-questionHeaderText'>
							{getUiText(currentLanguageId, 'challengeUiTexts', 'question')}
							{challengeIndex}
						</div>
					</div>
					<div className="Sort-question">
						{renderMarkdown(getTranslation(sortData.question.text, currentLanguageId))}
					</div>
				</div>
			</div>

			{/* Sort body */}
			<div id="SortBody" className="Sort-body">
				{/* Instructions */}
				<div className="Sort-instructions">
					{getTranslation(sortData.instructionText, currentLanguageId)}
				</div>
								
				{/* Boxes */}
				<div id="SortBoxes" className={'Sort-boxes Sort-boxes--' + sortData.boxes.length}>
					{sortData.boxes.map((box, index) => {
						let boxClass = 'Sort-boxContainer Sort-boxContainer--' + (index + 1);
						if (box.id === 'true') boxClass = boxClass + ' Sort-boxContainer--green';
						if (box.id === 'false') boxClass = boxClass + ' Sort-boxContainer--red';
						return (
							<div 
								key={index} 
								className={boxClass}
								onClick={() => {if (!isPaused) handleSelectBox(box.id);}}
							>
								<div className="Sort-boxText">
									{renderMarkdown(getTranslation(box.name, currentLanguageId))}
								</div>
							</div>
						);
					})}
				</div>
				
				{/* Items */}
				<div id="SortItems" className="Sort-items">
					{sortData.items.map((item, index) => {
						let className = 'Sort-item';
						if (sortData.completed) {
							className = className + ' Sort-item--placed';
						} else {
							if (index === selectedItemIndex) {
								if (animation) {
									className = className + ' Sort-item--' + animation;
								} else {
									className = className + ' Sort-item--selected';
								}
							} else {
								if (item.isPlaced) className = className + ' Sort-item--placed';	
							}
						}
						let bgImg = null;
						if (item.image) {
							bgImg = require('../../../assets/images/' + item.image);
							className = className + ' Sort-item--bgImg';
						}
						return (
							<div 
								key={index} 
								className={className}
								// style={bgImg ? {backgroundImage: 'url(' + bgImg + ')'} : null}
								onClick={() => {if (!isPaused && item.isPlaced === false) handleSelectItem(index);}}
							>
								{bgImg && <img src={bgImg} alt={getTranslation(item.name, currentLanguageId)} />}
								{renderMarkdown(getTranslation(item.name, currentLanguageId))}
							</div>
						);
					})}
				</div>	
			</div>
		</div>
	);
};

Sort.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	selectedItemIndex: PropTypes.number,
	animation: PropTypes.string,
	sortData: PropTypes.object.isRequired,
	handleSelectItem: PropTypes.func.isRequired,
	handleSelectBox: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default Sort;