import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import './connection-status.scss';

const ConnectionStatus = ({isOnline, currentLanguageId}) => {
	return (
		<div className={'ConnectionStatus' + (!isOnline ? ' ConnectionStatus--offline' : '')}>
			<div className="ConnectionStatus-text">
				{getUiText(currentLanguageId, 'errorUiTexts', 'offline')}
			</div>
		</div>
	);	
};

ConnectionStatus.propTypes = {
	isOnline: PropTypes.bool.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default ConnectionStatus;