import React from 'react';
import PropTypes from 'prop-types';
import './trophy.scss';

const Trophy = ({id}) => {
	let trophyId = 1;
	if (id !== undefined) {
		trophyId = id;
	}

	return (
		<div className={'Trophy'}>
			<div className={'Trophy-' + trophyId}/>
		</div>
	);
};

Trophy.defaultProps = {
	type: null
};

Trophy.propTypes = {
	id: PropTypes.number,
};

export default Trophy;