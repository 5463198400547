import React from 'react';
import marked from 'marked';
import { challengeUiTexts, cookieUiTexts, errorUiTexts, generalUiTexts, trophyRoomTexts } from 'data/ui-texts';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
function renderMarkdown(markdown) {
	let lexed = marked.lexer(markdown);
	let parsed = marked.parser(lexed);
	return <div dangerouslySetInnerHTML={{__html: parsed}} />;
}

/**
 * Gets text of given language from given category if possible.
 * @param {string} language 
 * @param {string} category 
 * @param {string} textId 
 */
export function getUiText(language, category, textId) {
	let resultText = null;

	switch (category) {
	case 'cookieUiTexts':
		resultText = cookieUiTexts[textId][language];
		break;
	case 'trophyRoomTexts':
		resultText = trophyRoomTexts[textId][language];
		break;
	case 'errorUiTexts':
		resultText = errorUiTexts[textId][language];
		break;
	case 'generalUiTexts':
		resultText = generalUiTexts[textId][language];
		break;
	case 'challengeUiTexts':
		resultText = challengeUiTexts[textId][language];
		break;
	default:
		console.error(errorUiTexts.couldNotFindTextCategory + ' ' + language + ', ' + textId);
	}

	// Translation was not found
	if (!resultText) {
		console.error(errorUiTexts.translationNotFound + ' ' + language + ', ' + textId);
		resultText = '';
	}
	return resultText;
}

export {renderMarkdown};