/* 
QUIZ TEMPLATE
{
	id: '',
	type: 'quiz',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: '',
		showNumberOfAnswers: true,
	},
	answers: [
		{id: 1, text: '', isSelected: false, isCorrect: false},
		{id: 2, text: '', isSelected: false, isCorrect: false},
		{id: 3, text: '', isSelected: false, isCorrect: false}
	],
	clues: null,
	firstClueIsFree: true
},

SORT TEMPLATE
{
	id: '',
	type: 'sort',
	roomId: null,
	challengeNumber: null,
    hexagonImageId: null,
	question: {
		text: ''
	},
	boxes: [
		{id: '', name: ''},
		{id: '', name: ''},
	],
	items: [
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''}
	]
},

PAIR TEMPLATE
{
	id: '',
	type: 'pairs',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: ''
	},
	columnA: [
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
	]
},
*/
const conceptsData = [
	{
		id: 'concepts-1',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 1,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'Data kan være noget, du løber tør for på din telefon. Men hvad betyder data egentligt?',
				en: 'You might run out of data on your phone. But what does data really mean?'
			},
			showNumberOfAnswers: true,
			image: null,
			showImageInPopup: false
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Det er den information, der bliver sendt til og fra din computer eller telefon',
					en: 'It is the information sent to and from your computer or phone'
				},
				isSelected: false,
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Data er kun oplysninger, der behandles elektronisk',
					en: 'Data is only information processed electronically'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Data er alle former for oplysninger uanset formen',
					en: 'Data is all kinds of information regardless of format'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-2',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 2,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'Hvad er det, der beskytter dine oplysninger, hvis du og vennerne laver en story på Snapchat?',
				en: 'What protects information about you if you and your friends make a story on Snapchat?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Kommunen',
					en: 'The municipality '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Grundloven',
					en: 'The Constitution'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Reglerne om databeskyttelse',
					en: 'The data protection rules'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 4, 
				text: {
					da: 'Folketinget',
					en: 'The Parliament'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-3',
		type: 'sort',
		roomId: 1,
		challengeNumber: 3,
		hexagonImageId: 2,
		instructionText: {
			da: 'Vælg en blå kasse, og tryk på en brun:',
			en: 'Pick a blue box, and press a brown one:'
		},
		question: {
			text: {
				da: 'Klik på én personoplysning ad gangen, og vælg den rigtige kategori.',
				en: 'Click on one type of personal data at a time and select the right category.'
			},
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'følsomme oplysninger',
					en: 'sensitive information'
				},
			},
			{
				id: '2', 
				name: {
					da: 'ikke følsomme oplysninger',
					en: 'not sensitive information'
				},
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Race',
					en: 'Race'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '2', 
				name: {
					da: 'Familieforhold',
					en: 'Family matters'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '3', 
				name: {
					da: 'Helbred',
					en: 'Health'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '4', 
				name: {
					da: 'Religion',
					en: 'Religion'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '5', 
				name: {
					da: 'Billede',
					en: 'Photo'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '6', 
				name: {
					da: 'E-mail',
					en: 'E-mail'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			}
		]
	},
	{
		id: 'concepts-4',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 4,
		hexagonImageId: 4,
		question: {
			text: {
				da: 'Josef lægger en video op af sig selv og sine venner på Youtube. Hvem er den registrerede?',
				en: 'Joseph uploads a video of himself and some friends on YouTube. Who is the data subject? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Det er YouTube, fordi videoen bliver uploadet på deres medie',
					en: 'YouTube is because the video is uploaded on their media'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Det er alle, der er med på videoen',
					en: 'Everyone in the video'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Alle, der ser videoen',
					en: 'Everyone watching the video'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-5',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 5,
		hexagonImageId: 5,
		question: {
			text: {
				da: 'Camillas fodboldklub behandler oplysninger om hende. Hvilken rolle har fodboldklubben?',
				en: 'Camilla\'s football club processes information about her. Which role does the football club have?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Den dataansvarlige',
					en: 'The data controller'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Den registrerede',
					en: 'The data subject'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-6',
		type: 'sort',
		roomId: 1,
		challengeNumber: 6,
		hexagonImageId: 5,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Camillas fodboldklub er den dataansvarlige. Hvad betyder det?',
				en: 'Camilla\'s football club is the data controller. What does that mean?'
			},
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				},
			},
			{
				id: '2',
				name: {
					da: 'falsk',
					en: 'false'
				},
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'De har ansvaret for at passe på hendes data',
					en: 'They are responsible for protecting her data'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '2', 
				name: {
					da: 'De har ansvaret for at overholde alle regler omkring Camillas personoplysninger',
					en: 'They are responsible for complying with all rules relating to Camilla’s personal data'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
		]
	},
	{
		id: 'concepts-7',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 7,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'Hvad er en cookie?',
				en: 'What is a cookie?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Det er alt det data, som bliver gemt på din computer, når du henter filer fra nettet',
					en: 'All data stored on your computer when you download internet files'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Det er den video, som youtube afspiller på din computer eller telefon',
					en: 'It is the video that YouTube plays on your computer or phone'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Det er en lille datafil, der indsamler digitale fodspor om dig, når du færdes online',
					en: 'It is a small data file that collects your digital whereabouts when you are online'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-8',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 8,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'Hvad betyder samtykke?',
				en: 'What does consent mean?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Man giver nogen lov til noget',
					en: 'You give someone permission to do something'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Det er noget, din lærer bestemmer',
					en: 'It is something your teacher decides'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-9',
		type: 'pairs',
		roomId: 1,
		challengeNumber: 9,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'Sæt en rettighed og en beskrivelse sammen. ',
				en: 'Select the right and description that belong together. '
			}
		},
		columnA: [
			{
				id: '1', 
				text: {
					da: 'Retten til indsigt',
					en: 'Right of access'
				}, 
				image: null, 
				isSelected: false, 
				connectedTo: null, 
				correctConnectionId: '2'
			},
			{
				id: '3', 
				text: {
					da: 'Retten til oplysning',
					en: 'Right to be informed'
				}, 
				image: null, 
				isSelected: false, 
				connectedTo: null, 
				correctConnectionId: '5'
			},
			{
				id: '4', 
				text: {
					da: 'Retten til sletning',
					en: 'Right to erasure'
				}, 
				image: null, 
				isSelected: false, 
				connectedTo: null, 
				correctConnectionId: '7'
			},
			{
				id: '6', 
				text: {
					da: 'Retten til berigtigelse',
					en: ' Right to rectification'
				}, 
				image: null, 
				isSelected: false, 
				connectedTo: null, 
				correctConnectionId: '8'
			}
		],
		columnB: [
			{
				id: '2', 
				text: {
					da: 'Du har ret til at se de personoplysninger, den dataansvarlige behandler om dig',
					en: 'You have the right to see your personal data handled by the data controller'
				}, 
				image: null, 
				isSelected: false
			},
			{
				id: '5', 
				text: {
					da: 'Du har ret til at få at vide, hvilke oplysninger der behandles om dig',
					en: 'You have the right to know which of your personal data is being processed'
				}, 
				image: null,
				isSelected: false
			},
			{
				id: '7', 
				text: {
					da: 'Du har ret til at få slettet oplysninger om dig',
					en: 'You have the right to have your personal data deleted'
				}, 
				image: null, 
				isSelected: false
			},
			{
				id: '8', 
				text: {
					da: 'Du har ret til at få rettet forkerte oplysninger om dig',
					en: 'You have the right to have inaccurate information about you corrected'
				}, 
				image: null, 
				isSelected: false
			}
		]
	},
	{
		id: 'concepts-10',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 10,
		hexagonImageId: 3,
		question: {
			text: {
				da: 'Josef er startet til hockey. Josefs træner har indhentet oplysninger om Josefs navn, e-mail og adresse. Må træneren det? ',
				en: 'Josef has started playing hockey. Josef\'s coach has collected information about Josef\'s name, e-mail and address. Is the coach allowed to do that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, hvis han har en god grund',
					en: 'Yes, if he has a good reason'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Nej, for han er kun træner',
					en: 'No, because he is just the coach'
				}, 
				isSelected: false,
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Ja, for de oplysninger kan alle alligevel få',
					en: 'Yes, because anyone can get this information'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-11',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 11,
		hexagonImageId: 4,
		question: {
			text: {
				da: 'Josefs storebror sender et billede til Josef. Billedet viser, han er færdiguddannet som læge. Må Josef lægge billedet på Facebook?',
				en: 'Josef\'s older brother sends a photo to Josef. The photo shows that he has qualified as a doctor. Can Josef post the photo on Facebook?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Nej, det er imod databeskyttelsesreglerne',
					en: 'No, it is against the data protection rules'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, det må han godt. Men det er god stil at spørge først',
					en: 'Yes he can. But it is a good idea to ask first'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-12',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 12,
		hexagonImageId: 5,
		question: {
			text: {
				da: 'Camilla har givet lov til, at hendes fodboldklub må lægge et billede op af hende på klubbens hjemmeside. Men nu fortryder hun og vil have det fjernet. Kan hun det?',
				en: 'Camilla has allowed her football club to post a photo of her on the club\'s website. But now she regrets and wants it removed. Can she do that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Nej, det er desværre for sent',
					en: 'No, unfortunately it is too late'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, man kan godt ombestemme sig',
					en: 'Yes, you can change your mind'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Ja, men så skal hun kontakte politiet',
					en: 'Yes, but then she has to contact the police'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-13',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 13,
		hexagonImageId: 2,
		question: {
			text: {
				da: 'I har haft idrætsdag. Din lærer har lagt et billede op af dig og dine klassekammerater på skolens hjemmeside. Må din lærer det? ',
				en: 'Today has been sports day. Your teacher has posted a photo of you and your classmates on the school\'s website. Is your teacher allowed to do that? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Nej, det er forbudt',
					en: 'No, that is not allowed'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, hvis I har givet lov',
					en: 'Yes, if you have given permission'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Ja, hvis skolen har en god grund til det',
					en: 'Yes, if the school has a good reason'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 4, 
				text: {
					da: 'Ja, hvis jeres forældre har givet lov',
					en: 'Yes, if your parents have given permission'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 5,
				text: {
					da: 'Ja, hvis billedet er meget pænt',
					en: 'Yes, if the photo is very good'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'concepts-14',
		type: 'quiz',
		roomId: 1,
		challengeNumber: 14,
		hexagonImageId: 6,
		question: {
			text: {
				da: 'Camilla har en e-mailkonto hos hotmail og vil gerne skifte til G-mail. Hun ønsker at få overflyttet sine kontakter til den nye konto. Har hun ret til det?',
				en: 'Camilla has a Hotmail account and wants to change to G-mail. She wants her contacts to be moved to the new account. Can she demand that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, det kan hun gennem indsigtsretten',
					en: 'Yes, she can do that under the right of access'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, det kan hun gennem dataportabilitet',
					en: 'Yes, she can do that via data portability'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Ja, det kan hun gennem oplysningsretten',
					en: 'Yes, she can do that under the right to information'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
];
export {
	conceptsData
};