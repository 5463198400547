import {icons} from 'data/highscore-data';

export function getIconType(points) {
	let iconType = icons.filter((icon) => {
		if (icon.hasOwnProperty('pointsMin') && icon.hasOwnProperty('pointsMax')) {
			if (points >= icon.pointsMin && points <= icon.pointsMax) return true;
			return false;
		}
		if (icon.hasOwnProperty('pointsMin') && points >= icon.pointsMin) return true;
		if (icon.hasOwnProperty('pointsMax') && points <= icon.pointsMax) return true;
		return false;
	})[0].id;

	return iconType;
}


export function checkIfRoomStreakUnlocked(roomChallengesData, playerData) {
	let errors = 0;
	roomChallengesData.forEach((challenge) => {
		if (errors > 0) return;
		if (!playerData.challenges.some((playerChallenge) => {return playerChallenge.id === challenge.id;})) return;

		let playerChallengeData = playerData.challenges.filter((playerChallenge) => {
			return playerChallenge.id === challenge.id;
		})[0];

		if (challenge.type === 'quiz') {
			let wrongAnswers = challenge.answers.filter((answer) => {
				return (answer.isCorrect === false && playerChallengeData.selectedAnswers.indexOf(answer.id) >= 0);
			}).length;
			errors = errors + wrongAnswers;
		}
		if (challenge.type === 'sort' || challenge.type === 'order' || challenge.type === 'pairs') {
			errors = errors + playerChallengeData.errors;
		}
	});
	return (errors === 0);
};