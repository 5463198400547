import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './warning-button.scss';

const WarningButton = ({warningText, roomIsOpen, roomName, roomId, isCurrentRoom, goToPage}) => {
	const [showWarning, setShowWarning] = useState(false);

	useEffect(() => {
		// Set timer in case warning is shown, to wait for transition to finish.
		let timer;
		if (showWarning) {
			timer = setTimeout(() => {
				setShowWarning(false);
			}, 3000);
		}

		// Cleanup in case of unmounting before timer is done
		return () => {
			clearTimeout(timer);
		};
	});

	return (
		<div className='WarningButton'>
			<div className={'WarningButton-button' + (isCurrentRoom ? '' : ' NotActive')} 
				onClick={() => {
					if (roomIsOpen) goToPage('room', roomId);
					else setShowWarning(true);
				}}>
				<div className='WarningButton-text'>
					{roomName}
				</div>
			</div>
			<div className={'WarningButton-wrapper' + (showWarning ? ' Show' : '')}>
				<div className={'WarningButton-warningLock'}/>
				<div className={'WarningButton-warning'}>{warningText}</div>
			</div>
		</div>
	);
};

WarningButton.propTypes = {
	warningText: PropTypes.string.isRequired,
	roomId: PropTypes.number.isRequired,
	roomName: PropTypes.string.isRequired,
	roomIsOpen: PropTypes.bool.isRequired,
	goToPage: PropTypes.func.isRequired,
	isCurrentRoom: PropTypes.bool.isRequired,
};

export default WarningButton;