/* 
QUIZ TEMPLATE
{
	id: '',
	type: 'quiz',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: '',
		showNumberOfAnswers: false,
		image: null,
		showImageInPopup: false
	},
	answers: [
		{id: 1, text: '', isSelected: false, isCorrect: false},
		{id: 2, text: '', isSelected: false, isCorrect: false},
		{id: 3, text: '', isSelected: false, isCorrect: false}
	],
	clues: [
		{id: 1, text: '', isRevealed: true},
		{id: 2, text: '', isRevealed: false},
		{id: 3, text: '', isRevealed: false},
	],
	firstClueIsFree: true
},

SORT TEMPLATE
{
	id: '',
	type: 'sort',
	roomId: null,
	challengeNumber: null,
    hexagonImageId: null,
	question: {
		text: ''
	},
	boxes: [
		{id: '', name: ''},
		{id: '', name: ''},
	],
	items: [
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''},
		{id: '', name: '', isPlaced: false, correctBoxId: ''}
	]
},

PAIR TEMPLATE
{
	id: '',
	type: 'pairs',
	roomId: null,
	challengeNumber: null,
	hexagonImageId: null,
	question: {
		text: ''
	},
	columnA: [
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
		{id: '', text: '', image: null, isSelected: false, connectedTo: null, correctConnectionId: null},
	],
	columnB: [
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
		{id: '', text: '', image: null, isSelected: false},
	]
},
*/
const rightsData = [
	{
		id: 'rights-1',
		type: 'sort',
		roomId: 2,
		challengeNumber: 16,
		hexagonImageId: 2,
		instructionText: {
			da: 'Vælg en, og tryk på sandt eller falsk:',
			en: 'Choose one and press true or false:'
		},
		question: {
			text: {
				da: 'Hvilke udsagn er sandt og falsk om <i>\'retten til oplysning\'?</i>',
				en: 'What statements are true and false about <i>\'the right to information\'?</i>'
			}
		},
		boxes: [
			{
				id: '1', 
				name: {
					da: 'sandt',
					en: 'true'
				}
			},
			{
				id: '2', 
				name: {
					da: 'falsk',
					en: 'false'
				}
			},
		],
		items: [
			{
				id: '1', 
				name: {
					da: 'Man kan få oplysninger om sine forældre eller om andre, som kan beslutte ting om éns liv',
					en: 'You can get information about your parents or others who can make decisions about your life'
				}, 
				isPlaced: false, 
				correctBoxId: '2'
			},
			{
				id: '2', 
				name: {
					da: 'Man skal have besked, når nogen modtager oplysninger om én selv',
					en: 'You must be notified when someone receives information about you'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			},
			{
				id: '3', 
				name: {
					da: 'Man skal have besked om, hvad éns oplysninger skal bruges til',
					en: 'You must be notified about what your personal data is used for'
				}, 
				isPlaced: false, 
				correctBoxId: '1'
			}
		]
	},
	{
		id: 'rights-2',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 17,
		hexagonImageId: 5,
		question: {
			text: {
				da: 'Camilla undrer sig over, hvorfor hendes fodboldklub ikke har fortalt hende, hvad de vil bruge hendes personoplysninger til. Skal fodboldklubben det?',
				en: 'Camilla is wondering why her football club has not told her what they need her personal data for. Is the football club required to do that? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Nej, Camilla skal selv huske at spørge ',
					en: 'No, Camilla has to remember to ask herself '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2,
				text: {
					da: 'Ja, de har pligt til at fortælle, hvad de gør med hendes oplysninger, uden hun behøver at spørge',
					en: 'Yes, they are obligated to inform her what they are doing with her personal data without her having to ask'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Nej, de behøver ikke at sige noget, så længe de har en god grund til at indsamle oplysningerne',
					en: 'No, they do not have to tell her as long as they have a good reason for collecting the information'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-3',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 18,
		hexagonImageId: 6,
		question: {
			text: {
				da: 'Camilla har været i Lalandia. Hun deltog i en konkurrence og skulle indtaste sine oplysninger. Nu er konkurrencen slut. Camilla ønsker, at de sletter hendes oplysninger. Har hun ret til det?',
				en: 'Camilla has visited a holiday resort. She participated in a competition and had to hand over her personal data. The competition is now over. Camilla wants them to erase her personal data. Can she demand that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, hun kan altid skrive til Lalandia og bede dem om at slette oplysningerne',
					en: 'Yes, she can always write to the holiday resort and ask them to erase her information'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Nej. Det er et krav, at Lalandia skal gemme oplysningerne',
					en: 'No. The holiday resort is required to store the information'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3,
				text: {
					da: 'Nej, for hun kunne jo bare lade være med at deltage',
					en: 'No, because participating was her own choice'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-4',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 19,
		hexagonImageId: 6,
		question: {
			text: {
				da: 'Camilla er stoppet til fodbold. Hun vil gerne have sine oplysninger slettet fra klubbens system. Kan hun det?',
				en: 'Camilla has stopped playing football. She wants her personal data to be erased from the club\'s system. Can she do that?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja. Hvis klubben ikke længere har en god grund til at behandle Camillas oplysninger',
					en: 'Yes. If the club no longer has a good reason for processing Camilla\'s personal data'
				}, 
				isSelected: false,
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Nej. Klubben må beholde dem i 10 år. Det kan jo være, at hun kommer tilbage',
					en: 'No. The club may keep it for 10 years. She might come back'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Nej. Hun har tidligere givet lov, så klubben må gerne beholde dem',
					en: 'No. She has previously given permission, so the club is allowed to keep it'
				}, 
				isSelected: false,
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-5',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 20,
		hexagonImageId: 10,
		question: {
			text: {
				da: 'Camillias storesøster Siska har været ude at shoppe. Siska kommer hjem og fortæller, at butikken har filmet hende. Kan Camilla få indsigt i filmen?',
				en: 'Camilla\'s older sister Siska has been out shopping. Siska comes home and says that the shop filmed her. Can Camilla get access to the film?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja. Retten til indsigt gør, at Camilla må få videoen',
					en: 'Yes. The right of access allows Camilla to get the video '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Nej, det kan Camilla ikke',
					en: 'No, she cannot'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Camilla kan ikke få videoen, men Siska kan selv bede butikken om den og få den udleveret',
					en: 'Camilla cannot get the video, but Siska can ask the shop to give it to her'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-6',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 21,
		hexagonImageId: 4,
		question: {
			text: {
				da: 'Josef opdager, at hans skole har hans telefonnummer og adresse i systemet. Det bryder han sig ikke om. Har han ret til at få dem slettet?',
				en: 'Josef finds out that his school keeps his phone number and address in the system. He does not like that. Does he have the right to have them erased?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, han skal bare bede om det ',
					en: 'Yes, he just has to ask for it '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Nej, skolen har en god grund til at have oplysningerne, da han er elev på skolen',
					en: 'No, the school has a good reason to keep the information since he is a pupil at the school'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 3, 
				text: {
					da: 'Ja, han kan bare selv gå ind og gøre det ',
					en: 'Yes, he can just do so himself '
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-7',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 22,
		hexagonImageId: 7,
		question: {
			text: {
				da: 'Josef har fået 7 i idræt. Det er han utilfreds med. Han mener, han skulle have haft 10. Har han ret til at få karakteren ændret? ',
				en: 'Josef got a C in sports. He does not think it is fair. He thinks he should have had a B. Does he have the right to have the mark changed? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja selvfølgelig, han ved bedst',
					en: 'Yes of course, he knows best'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja, for det er ikke fair, og derfor bør det ændres',
					en: 'Yes, because it is not fair and should therefore be changed '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3,
				text: {
					da: 'Nej, for det er lærerens vurdering',
					en: 'No, because it is the teacher’s assessment '
				}, 
				isSelected: false,
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-8',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 23,
		hexagonImageId: 8,
		question: {
			text: {
				da: 'Josef skal starte til svømning. Svømmeklubben spørger om hans personoplysninger. Hvad skal Josef gøre for at få at vide, hvad de vil bruge hans oplysninger til?',
				en: 'Josef is starting swimming lessons. The swimming club asks for his personal data. What should Josef do if he wants to know what they need his personal data for?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ikke noget. Det er klubbens pligt at fortælle det',
					en: 'Nothing. The club has an obligation to tell him'
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Han har ikke ret til at vide noget',
					en: 'He does not have the right to know'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Han skal skrive til klubben',
					en: 'He should ask the club'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-9',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 24,
		hexagonImageId: 4,
		question: {
			text: {
				da: 'Josef spiller Fortnite. Josef vil gerne vide, hvor meget spilletid, han har. Har Josef ret til det efter reglerne om databeskyttelse?',
				en: 'Josef plays Fortnite. Josef wants to know how much playtime he has? Is Josef entitled to that under the data protection rules? '
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja, det er en del af den registreredes rettigheder',
					en: 'Yes, that is part of the rights of the data subject '
				}, 
				isSelected: false, 
				isCorrect: true
			},
			{
				id: 2, 
				text: {
					da: 'Nej, det er der ikke nogen regler om',
					en: 'No, there are no rules about that'
				}, 
				isSelected: false, 
				isCorrect: false
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-10',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 25,
		hexagonImageId: 12,
		question: {
			text: {
				da: 'Camillas store hobby er slægtsforskning. Hun har fundet en professionel side på nettet, hvor en forsker har skrevet Camillas mormors navn i et forkert stamtræ. Kan Camilla få navnet slettet?',
				en: 'Camilla\'s hobby is family history research. She has found a professional website where a researcher has written Camilla\'s grandmother\'s name in the wrong family tree. Can Camilla have the name erased?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja. Hun kan godt få navnet slettet, da det står i det forkerte stamtræ',
					en: 'Yes. She can have the name erased as it is in the wrong family tree'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Ja. Camilla kan få navnet slettet, da hun er den yngste i familien',
					en: 'Yes. Camilla can have the name erased as she is the youngest member of the family'
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 3, 
				text: {
					da: 'Nej. Men Camillas mormor kan selv få navnet slettet, da det handler om hende',
					en: 'No. But Camilla’s grandmother can get the name erased, since the information is about her'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
	{
		id: 'rights-11',
		type: 'quiz',
		roomId: 2,
		challengeNumber: 26,
		hexagonImageId: 11,
		question: {
			text: {
				da: 'Camilla spiser cocopops. På æsken står der, at de er lavet af ægte chokolade. Camillas mor har sagt, at det ikke er rigtigt. Kan de få rettet oplysningen, hvis den er forkert?',
				en: 'Camilla is eating cereal. The package says that they are made of real chocolate. Camilla\'s mother says that it is not true. Can they have the information rectified if it is wrong?'
			},
			showNumberOfAnswers: true,
		},
		answers: [
			{
				id: 1, 
				text: {
					da: 'Ja. Det har de ret til, fordi det er faktuelt forkerte oplysninger ',
					en: 'Yes. They can have it changed because the information is factually wrong '
				}, 
				isSelected: false, 
				isCorrect: false
			},
			{
				id: 2, 
				text: {
					da: 'Nej, det er ikke en personoplysning',
					en: 'No, this is not personal data'
				}, 
				isSelected: false, 
				isCorrect: true
			}
		],
		clues: null,
		firstClueIsFree: true
	},
];

export {
	rightsData
};