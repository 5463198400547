let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

// let apiURL = 'http://datadyst.local/api/index.php/';
// if (env === 'test') {
let apiURL = 'https://datadyst.cphgamelabtest.dk/api/index.php/';
// }

if (env === 'production') {
	apiURL = 'https://datadysten.dk/api/index.php/';
}

const languages = [
	'da',
	'en',
];

const defaultLanguage = 'da';

const appConfig = {
	env: env,
	name: 'datadyst',
	apiURL: apiURL,
	userNameMinLength: 3,
	userNameMaxLength: 15,
	useStreaks: false,
	playerDataCookieName: 'datadyst_playerData',
	languages: languages,
	defaultLanguage: defaultLanguage,
	availabilityStatementLink: 'https://www.was.digst.dk/datadysten-dk',

};

export default appConfig;