import React from 'react';
import imagesData from 'data/images-data';
import './image-loader.scss';

const ImageLoader = () => {
	return (
		<div className="ImageLoader">
			{imagesData.map((fileName, index) => {
				let imgSrc = 'this-file-does-not-exist';
				try {
					imgSrc = require('assets/images/' + fileName).default;
				} catch (e) {
					console.error('Could not find image: assets/images/' + fileName);
				}
				return (
					<img 
						key={index}
						src={imgSrc}
						alt="preload"
					/>
				);				
			})}
		</div>
	);
};


export default ImageLoader;