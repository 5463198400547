import React from 'react';
import PropTypes from 'prop-types';
import './hexagon.scss';
import { getUiText } from 'helpers/text-helper';

const Hexagon = ({id, textId, children, currentLanguageId}) => {
	let innerImageIndex = 1;
	if (id !== undefined) {
		innerImageIndex = id;
	}
	let innerTextId = 0;
	if (textId !== undefined) {
		innerTextId = textId;
	}

	return (
		<div className={'Hexagon'}>
			<div className={'Hexagon-inner-' + innerImageIndex}>
				<div className={'Hexagon-text-' + innerTextId}>
					<div className='Hexagon-text-header'>
						{getUiText(currentLanguageId, 'trophyRoomTexts', 'congratulations')}
					</div>
					<div className={'Hexagon-text-body'}>
						{getUiText(currentLanguageId, 'trophyRoomTexts', 'youGot')}
						<div className={'Hexagon-trophy'}>{children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Hexagon.defaultProps = {
	type: null
};

Hexagon.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	id: PropTypes.number,
	textId: PropTypes.number,
	children: PropTypes.string
};

export default Hexagon;

/*
Id to image
 1	- Josef & Camilla - landing
 2	- Josef & Camilla
 3	- Josef hockey
 4	- Josef telefon
 5	- Camilla bold
 6	- Camilla
 7	- Josef bold
 8	- Josef
 9	- Camillas Mor pegefinger
 10	- Camilla telefon
 11	- Camillas mor undrende
 12	- Camillas mor glad
 13 - Vred Josef
 14 - Camilla undrende
 */