import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {shuffleArray} from 'helpers/array-helper';
import {singlePairPoints, multiplePairsPoints, streakPoints} from 'data/points-data';
import Pairs from './pairs';

class PairsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPaused: false,
			pairsData: null
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load challenge */
		this.loadChallenge();
	}

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.challengeData.id !== prevProps.challengeData.id) {
			this.setState({isLoading: true, pairsData: null}, () => {this.loadChallenge();});
		} 
	}

	/**
	 * Load pairs & sync with player
	 */
	loadChallenge = () => {
		/* Get pairs data */
		let challengeData = JSON.parse(JSON.stringify(this.props.challengeData));
		challengeData.completed = false;
		challengeData.errors = 0;
		challengeData.correctAnswers = 0;

		/* Shuffle items in columns */
		challengeData.columnA = shuffleArray(challengeData.columnA);
		challengeData.columnB = shuffleArray(challengeData.columnB);

		/* Sync with player progress */
		if (
			this.props.playerData.hasOwnProperty('challenges') &&
			this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				challengeData.completed = playerChallengeData.completed;
			}
			challengeData.errors = playerChallengeData.errors;
		}

		/* Update state */
		this.setState({isLoading: false, isPaused: challengeData.completed, pairsData: challengeData});
	}

	/**
	 * Select card
	 * @param {string} columnId
	 * @param {number} cardIndex
	 */
	handleSelectCard = (columnId, cardIndex) => {
		if (this.state.isPaused) return;
		
		this.setState({isPaused: true}, () => {
			let pairsData = JSON.parse(JSON.stringify(this.state.pairsData));

			/* Card is already connected - return */
			if (
				(columnId === 'columnA' && pairsData['columnA'][cardIndex].connectedTo !== null) ||
				(columnId === 'columnB' && pairsData['columnA'].some((card) => {
					return card.connectedTo === pairsData['columnB'][cardIndex].id;
				}))
			) {
				this.setState({isPaused: false});
				return;
			}

			/* Card is not connected - selected it */	
			if (!pairsData[columnId].some((card) => {return card.isSelected === true;})) {
				/* No cards are selected in that column - select card */
				pairsData[columnId][cardIndex].isSelected = true;		
			} else {
				/* A card in that column is already selected */
				let selectedCardIndex = pairsData[columnId].findIndex((card) => {return card.isSelected === true;});
				/* Different card: select new card instead */
				if (selectedCardIndex !== cardIndex) {
					pairsData[columnId][selectedCardIndex].isSelected = false;
					pairsData[columnId][selectedCardIndex].connectedTo = null;
					pairsData[columnId][cardIndex].isSelected = true;
				/* Same card: deselect card */
				} else {
					pairsData[columnId][cardIndex].isSelected = false;
					pairsData[columnId][cardIndex].connectedTo = null;
				}
			}

			/* Check if two non-connected cards are selected */
			let cardAIndex = pairsData['columnA'].findIndex((card) => {return card.isSelected === true;});
			let cardBIndex = pairsData['columnB'].findIndex((card) => {return card.isSelected === true;});
			let twoCardsAreSelected = (cardAIndex >= 0 && cardBIndex >= 0);

			/* Max 1 card selected - return */
			if (!twoCardsAreSelected) {
				this.setState({isPaused: false, pairsData});
				return;
			}

			/* Two cards selected  */
			/* Connect cards */
			pairsData['columnA'][cardAIndex].isSelected = false;
			pairsData['columnA'][cardAIndex].connectedTo = pairsData['columnB'][cardBIndex].id;
			pairsData['columnB'][cardBIndex].isSelected = false;

			/* Check if connection is correct */
			let isCorrectConnection = (
				pairsData['columnA'][cardAIndex].hasOwnProperty('correctConnectionId') && 
				pairsData['columnA'][cardAIndex].connectedTo === 
					pairsData['columnA'][cardAIndex].correctConnectionId
			); 

			/* Increase number of errors if not correct */
			if (!isCorrectConnection) {
				pairsData.errors = pairsData.errors + 1;
			} else {
				pairsData.correctAnswers = pairsData.correctAnswers + 1;
			}

			/* Update game data */
			this.setState({pairsData: pairsData});

			/* Player data - progress & errors */
			let playerChallenges = [];
			if (this.props.playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
			}
			let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
				return challenge.id === pairsData.id;
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: pairsData.id, 
					completed: false, 
					errors: pairsData.errors, 
					correctAnswers: pairsData.correctAnswers,
					roomId: this.props.roomId,
				});
			} else {
				playerChallenges[playerChallengeIndex].errors = pairsData.errors;
				playerChallenges[playerChallengeIndex].roomId = this.props.roomId;
			}

			/* Player data - streak */
			let streakUnlocked = false;
			let playerStreaks = {};
			if (appConfig.useStreaks) {
				if (this.props.playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(this.props.playerData.streaks));
				}
				if (this.props.roomId !== 'bonus') {
					if (!playerStreaks.hasOwnProperty('room' + this.props.roomId.toString())) {
						playerStreaks['room' + this.props.roomId.toString()] = 0;
					}
					if (playerStreaks['room' + this.props.roomId.toString()] !== -1) {
						if (isCorrectConnection) {
							playerStreaks['room' + this.props.roomId.toString()] = 
									playerStreaks['room' + this.props.roomId.toString()] + 1;
						} else {
							playerStreaks['room' + this.props.roomId.toString()] = 0;	
						}
						let roomStreak = parseInt(playerStreaks['room' + this.props.roomId.toString()]);
						if (roomStreak === streakPoints.streakMarker) {
							streakUnlocked = true;
							playerStreaks['room' + this.props.roomId.toString()] = -1;
						}
					}
				}
			}
			
			/* Update player data */
			this.props.updatePlayerData({
				challenges: playerChallenges,
			}).then((response) => {
				if (response.status === 'ok') {
					let challengeIsComplete = true;
					if (isCorrectConnection) {
						/* Check if all pairs are connected correctly */
						pairsData['columnA'].forEach((card) => {
							if (
								card.hasOwnProperty('correctConnectionId') && 
										(card.connectedTo === null || card.connectedTo !== card.correctConnectionId)
							) challengeIsComplete = false;
						});
					} else {
						/* Break connection */
						challengeIsComplete = false;
					}

					/* Update game data */
					if (!isCorrectConnection) pairsData['columnA'][cardAIndex].connectedTo = null;

					/* Update challenge data */
					let points = this.calculatePoints(pairsData);
					if (pairsData.correctAnswers === 0 && !isCorrectConnection) {
						points = 0;
					}

					/* Set animation timeout */
					this.timeout = setTimeout(() => {
						if (challengeIsComplete) {		
							this.handleCompleteAllPairs(streakUnlocked);
						} else {
							this.props.updateChallengeData(
								pairsData.id,
								points
							);
							this.setState({isPaused: false, pairsData: pairsData});
							if (streakUnlocked) this.props.toggleStreakPopup(true);
						}
					}, 500);
				}
			});
			
		});
	}

	/**
	 * Calculates points for challenge
	 * Calculates points based on pairsData and amount of wrong answers
	 * @param {*} pairsData
	 * @returns 
	 */
	calculatePoints = (pairsData) => {
		let numberOfPairs = pairsData.columnA.filter((card) => {
			return card.hasOwnProperty('correctConnectionId');
		}).length;

		let pairsPoints = (numberOfPairs === 1 ? singlePairPoints : multiplePairsPoints);
		let points = pairsPoints.minPoints;
		let pointIndex = pairsPoints.pointLimits.findIndex((limit) => {return pairsData.errors <= limit;});
		if (pointIndex >= 0) points = pairsPoints.pointValues[pointIndex];

		return points;
	}

	/**
	 * Complete all pairs
	 * Calculate points, update player data, give feedback, update player data
	 * @param {bool} streakUnlocked
	 */
	handleCompleteAllPairs = (streakUnlocked) => {
		let challengeData = JSON.parse(JSON.stringify(this.state.pairsData));
		challengeData.completed = true;

		const points = this.calculatePoints(challengeData);

		// this.props.completeChallenge(challengeData.id, points, null, streakUnlocked);
		// this.setState({pairsData: challengeData});
		this.props.completeChallenge(challengeData.id, points, null, streakUnlocked).then(() => {
			this.setState({pairsData: challengeData});
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		if (!this.state.isLoading && this.state.pairsData) {
			return (
				<Pairs 
					isPaused={this.state.isPaused} 
					pairsData={this.state.pairsData} 
					handleSelectCard={this.handleSelectCard}
					challengeIndex={this.props.challengeIndex}
					currentLanguageId={this.props.currentLanguageId}
				/>
			);
		}
		return null;
	}
}

PairsController.propTypes = {
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	challengeData: PropTypes.object.isRequired,
	playerData: PropTypes.object.isRequired,
	toggleStreakPopup: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateChallengeData: PropTypes.func.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default PairsController;