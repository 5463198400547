import React from 'react';
import PropTypes from 'prop-types';
// import {getIconType} from 'helpers/points-helper';
import FramedBox from 'components/layout/framed-box';
import './popup.scss';

const Popup = ({showPopup, frameText, type, title, text, textObj, points, buttons, onClick, onClose}) => {
	
	return (
		<div className={'Popup Popup--' + type + (showPopup ? ' Popup--show' : '')} onClick={onClick ? onClick : null}>
			<div className="Popup-body">
				{onClose && <div className="Popup-close" onClick={onClose} />}

				{type.includes('roomIntro') && <div className="Popup-pin-container"><div className="Popup-pin" /></div>}
				
				{title && <div className="Popup-title">{title}</div>}
				{(text && frameText) && 
					<FramedBox><div className="Popup-text" dangerouslySetInnerHTML={{__html: text}} /></FramedBox>}
				{(text && !frameText) && <div className="Popup-text" dangerouslySetInnerHTML={{__html: text}} />}
				{type === 'allRoomsCompleted' && <div className={'Popup-icon Popup-icon--trophy'} />}
				{((type === 'streak' || type === 'roomCompleted') && points !== null) && 
					<div className="Popup-points">+{points}p</div>}
				{textObj && textObj}
				{buttons && <div className="Popup-buttons">
					{buttons.map((button, index) => {
						return (
							<div 
								key={index} 
								className="Popup-button" 
								onClick={() => {button.action(...button.params);}}
							>{button.text}</div>
						);
					})}
				</div>}
			</div>
		</div>
	);
};

Popup.defaultProps = {
	frameText: false,
	title: null,
	text: null,
	feedback: null,
	points: null,
	image: null
};

Popup.propTypes = {
	showPopup: PropTypes.bool.isRequired,
	frameText: PropTypes.bool,
	type: PropTypes.string.isRequired,
	title: PropTypes.string,
	text: PropTypes.string,
	textObj: PropTypes.object,
	points: PropTypes.number,
	buttons: PropTypes.array,
	onClick: PropTypes.func,
	onClose: PropTypes.func
};

export default Popup;