import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {sortArrayByProperty, shuffleArray} from 'helpers/array-helper';
import {sortPoints, streakPoints} from 'data/points-data';
import Sort from './sort';

class SortController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isPaused: false,
			animation: null,
			sortData: null,
			selectedItemIndex: null
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Load challenge */
		this.loadChallenge();
	}

	/**
	 * Component updated
	 * @param {object} prevProps
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.challengeData.id !== prevProps.challengeData.id) {
			this.setState({isLoading: true, sortData: null}, () => {this.loadChallenge();});
		} 
	}

	/**
	 * Load sort & sync with player
	 */
	loadChallenge = () => {
		/* Get sort data */
		let challengeData = JSON.parse(JSON.stringify(this.props.challengeData));
		challengeData.completed = false;
		challengeData.errors = 0;

		/* Sort / shuffle items */
		if (challengeData.hasOwnProperty('sortItems') && challengeData.sortItems === true) {
			challengeData.items = sortArrayByProperty(challengeData.items, 'id', 'ASC');
		} else {
			challengeData.items = shuffleArray(challengeData.items);
		}
		

		/* Sync with player progress */
		if (
			this.props.playerData.hasOwnProperty('challenges') &&
				this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
		) {
			let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
				return challenge.id === challengeData.id;
			})[0];
			if (playerChallengeData.hasOwnProperty('completed')) {
				challengeData.completed = playerChallengeData.completed;
			}
			challengeData.errors = playerChallengeData.errors;
			challengeData.items.forEach((item) => {
				if (playerChallengeData.sortedItems.indexOf(item.id) >= 0) {
					item.isPlaced = true;
				}
			});
		}

		/* Update state */
		this.setState({isLoading: false, isPaused: challengeData.completed, sortData: challengeData}, () => {

		});
	}

	/**
	 * Select item
	 * @param {number} itemIndex
	 */
	handleSelectItem = (itemIndex) => {
		if (this.state.isPaused) return;

		this.setState({isPaused: true}, () => {
			let selectedItemIndex = (this.state.selectedItemIndex === itemIndex ? null : itemIndex);
			this.setState({isPaused: false, selectedItemIndex});
		});
	}

	/**
	 * Select box
	 * @param {string} boxId
	 */
	handleSelectBox = (boxId) => {
		if (this.state.isPaused || this.state.selectedItemIndex === null) return;

		this.setState({isPaused: true}, () => {
			let challengeData = JSON.parse(JSON.stringify(this.state.sortData));
			let isCorrect = (challengeData.items[this.state.selectedItemIndex].correctBoxId === boxId);
			
			/* Update game data */
			if (!isCorrect) {
				/* Wrong placement */
				challengeData.errors = challengeData.errors + 1;
				this.setState({animation: 'wrong', sortData: challengeData});
			/* Correct placement */
			} else {
				challengeData.items[this.state.selectedItemIndex].isPlaced = true;
				this.setState({animation: 'correct', sortData: challengeData});
			}			
			/* Player data - progress and errors */
			let playerChallenges = [];
			let sortedItems = [];
			challengeData.items.forEach((item) => {
				if (item.isPlaced) sortedItems.push(item.id);
			});
			if (this.props.playerData.hasOwnProperty('challenges')) {
				playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
			}
			let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
				return challenge.id === challengeData.id;
			});
			if (playerChallengeIndex === -1) {
				playerChallenges.push({
					id: challengeData.id, 
					completed: false, 
					errors: challengeData.errors, 
					sortedItems: sortedItems, 
					roomId: this.props.roomId
				});
			} else {
				playerChallenges[playerChallengeIndex].errors = challengeData.errors;
				playerChallenges[playerChallengeIndex].sortedItems = sortedItems;
				playerChallenges[playerChallengeIndex].roomId = this.props.roomId;
			}
			
			/* Player data - streaks */
			let streakUnlocked = false;
			let playerStreaks = {};
			if (appConfig.useStreaks) {
				if (this.props.playerData.hasOwnProperty('streaks')) {
					playerStreaks = JSON.parse(JSON.stringify(this.props.playerData.streaks));
				}

				if (this.props.roomId !== 'bonus') {
					if (!playerStreaks.hasOwnProperty('room' + this.props.roomId.toString())) {
						playerStreaks['room' + this.props.roomId.toString()] = 0;
					}
					if (playerStreaks['room' + this.props.roomId.toString()] !== -1) {
						if (isCorrect) {
							playerStreaks['room' + this.props.roomId.toString()] = 
								playerStreaks['room' + this.props.roomId.toString()] + 1;
						} else {
							playerStreaks['room' + this.props.roomId.toString()] = 0;	
						}
						let roomStreak = parseInt(playerStreaks['room' + this.props.roomId.toString()]);
						if (roomStreak === streakPoints.streakMarker) {
							streakUnlocked = true;
							playerStreaks['room' + this.props.roomId.toString()] = -1;
						}
					}
				}
			}

			/* Update player data */
			this.props.updatePlayerData({
				challenges: playerChallenges,
			}).then((response) => {
				if (response.status === 'ok') {
					this.timeout = setTimeout(() => {
						/* Check if all items have been sorted */
						let challengeIsComplete = 
							!challengeData.items.some((item) => {return item.isPlaced === false;});
						
						/* Update challenge data */
						let points = this.calculatePoints(challengeData);
						if (sortedItems.length === 0 && !isCorrect) {
							points = 0;
						}
						this.props.updateChallengeData(
							challengeData.id,
							points
						);

						if (challengeIsComplete) {
							/* Update game state, handle challenge completed */
							this.setState({selectedItemIndex: null, animation: null});
							this.sortingCompleted(streakUnlocked);
						} else {
							/* Update game state, show streak popup */
							this.setState({isPaused: false, selectedItemIndex: null, animation: null});
							if (streakUnlocked) this.props.toggleStreakPopup(true);
						}
					}, 500);
				}
			});
		});
	}

	/**
	 * Calculates points for challenge
	 * Calculates points based on sortData and amount of wrong answers
	 * @param {*} sortData
	 * @returns 
	 */
	calculatePoints = (sortData) => {
		let points = sortPoints.minPoints;
		let pointIndex = sortPoints.pointLimits.findIndex((limit) => {return sortData.errors <= limit;});
		if (pointIndex >= 0) points = sortPoints.pointValues[pointIndex];

		return points;
	}

	/**
	 * Sorting completed
	 * @param {bool} streakUnlocked
	 */
	sortingCompleted =(streakUnlocked) => {
		let challengeData = JSON.parse(JSON.stringify(this.state.sortData));
		challengeData.completed = true;

		const points = this.calculatePoints(challengeData);
		this.props.completeChallenge(challengeData.id, points, null, streakUnlocked).then(() => {
			this.setState({sortData: challengeData});
		});

	}

	/**
	 * Render component
	 */
	render = () => {
		if (!this.state.isLoading && this.state.sortData) {
			return (
				<Sort 
					isPaused={this.state.isPaused} 
					selectedItemIndex={this.state.selectedItemIndex}
					animation={this.state.animation}
					sortData={this.state.sortData} 
					handleSelectItem={this.handleSelectItem}
					handleSelectBox={this.handleSelectBox}
					challengeIndex={this.props.challengeIndex}
					currentLanguageId={this.props.currentLanguageId}
				/>
			);
		}
		return null;
	}
}

SortController.propTypes = {
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),	
	challengeData: PropTypes.object.isRequired,
	toggleStreakPopup: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateChallengeData: PropTypes.func.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default SortController;