import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import FramedBox from 'components/layout/framed-box';
import './order.scss';
import { getTranslation } from 'helpers/language-helper';

const Order = ({
	isPaused, 
	selectedItemId, 
	animation, 
	orderData, 
	handleSelectItem, 
	handleSelectBox, 
	currentLanguageId
}) => {
	return (
		<div className={'Order Order--' + orderData.id + (isPaused ? ' Order--paused' : '')}>

			{/* Order header */}
			<div id="OrderHeader" className="Order-header">
				{/* Question */}
				<FramedBox><div className="Order-question">
					{renderMarkdown(getTranslation(orderData.question.text, currentLanguageId))}
				</div></FramedBox>
				
				{/* Boxes */}
				<div className="Order-boxes">
					{orderData.boxes.map((box, index) => {
						let text = getTranslation(box.name, currentLanguageId);
						let className = 'Order-boxContainer Order-boxContainer--' + (index + 1) + 
							' Order-boxContainer--' + (box.placedItemId === box.correctItemId ? 'correct' : 'wrong');
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {if (!isPaused && box.placedItemId === null) handleSelectBox(index);}}
							>
								<div className="Order-boxText">{text}</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Order body */}
			<div id="OrderBody" className="Order-body">
				{/* Items */}
				<div className="Order-items">
					{orderData.items.map((item, index) => {
						let className = 'Order-item';
						let boxIndex = orderData.boxes.findIndex((box) => {return box.placedItemId === item.id;});
						let isPlaced = boxIndex >= 0; 
						if (orderData.completed) {
							className = className + ' Order-item--placed';
						} else {
							if (item.id === selectedItemId) {
								if (animation) {
									className = className + ' Order-item--' + animation;
								} else {
									className = className + ' Order-item--selected';
								}
							} else {
								if (isPlaced) className = className + ' Order-item--placed';
							}
						}
						return (
							<div key={index} className="Order-itemWrap">
								<div 
									className={className}
									onClick={() => {if (!isPaused && !isPlaced) handleSelectItem(item.id);}}
								>{renderMarkdown(getTranslation(item.name, currentLanguageId))}</div>
								{(isPlaced && item.id !== selectedItemId) && 
									<div className="Order-itemNumber">{boxIndex + 1}</div>}
							</div>
						);
					})}
				</div>			
			</div>
		</div>
	);
};

Order.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	selectedItemId: PropTypes.string,
	animation: PropTypes.string,
	orderData: PropTypes.object.isRequired,
	handleSelectItem: PropTypes.func.isRequired,
	handleSelectBox: PropTypes.func.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default Order;