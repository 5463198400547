import React from 'react';
import './language-button.scss';
import PropTypes from 'prop-types';

const LanguageButton = ({currentLanguageId, setCurrentLanguageId}) => {

	return (
		<div className='LanguageButton'>
			{currentLanguageId === 'da' ?
				<div className='LanguageButton-icon en' onClick={() => {setCurrentLanguageId('en');}}/>
				:
				<div className='LanguageButton-icon da' onClick={() => {setCurrentLanguageId('da');}}/>
			}
		</div>
	);
};

LanguageButton.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default LanguageButton;