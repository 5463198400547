/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import './room-overview.scss';
import appConfig from 'config/app.config';
import GameLogo from 'components/layout/game-logo';
import Phone from 'components/layout/phone';
import Hexagon from 'components/layout/hexagon';
import WarningButton from 'components/warning-button/warning-button';
import { getIsRoomCompleted, getTrophyId } from 'helpers/room-data-helper';
import Trophy from 'components/layout/trophy';
import LanguageButton from 'components/language-button/language-button';
import { getTranslation } from 'helpers/language-helper';

const RoomOverview = ({userCanSelectRoom, rooms, goToPage, resetPlayerData, playerData, currentLanguageId, setCurrentLanguageId}) => {
	return (
		<div id="RoomOverview" className={'RoomOverview'}>
			<div className='RoomOverview-languageButton'>
				<LanguageButton  
					currentLanguageId={currentLanguageId} 
					setCurrentLanguageId={setCurrentLanguageId} 
				/>
			</div>
			<div className="RoomOverview-wrapper">
				<div className="RoomOverview-welcome">
					<div className='RoomOverview-welcomeText'>
						{getUiText(currentLanguageId, 'generalUiTexts', 'welcome')}
					</div>
				</div>
				<div className={'RoomOverview-contentContainer'}>
					<Phone />
					<GameLogo currentLanguageId={currentLanguageId}/>
				</div>
				<div className="RoomOverview-chooseRooms">
					<div className="RoomOverview-chooseRoom">
						{getUiText(currentLanguageId, 'generalUiTexts', 'chooseRoom')}
					</div>
					{rooms.length > 0 &&
					<div className="RoomOverview-rooms">
						{rooms.map((room) => {
							return (
								<div key={room.id} className={'RoomOverview-room'}>
									<WarningButton
										warningText={
											getUiText(currentLanguageId, 'generalUiTexts', 'play')
											+ ' '
											+ getTranslation(room.prerequisite, currentLanguageId)
											+ ' '
											+ getUiText(currentLanguageId, 'generalUiTexts', 'first')
										}
										roomId={room.id}
										roomName={getTranslation(room.name, currentLanguageId)}
										roomIsOpen={(userCanSelectRoom && room.prerequisiteId ? getIsRoomCompleted(playerData, room.prerequisiteId) : true)}
										goToPage={goToPage}
										isCurrentRoom={true}
									/>
									<Trophy className="RoomOverView-trophy" id={getTrophyId(room.id, playerData)}/>
								</div>
							);
						})}
					</div>}
				</div>
			</div>
			<div className="RoomOverview-logoWrapper">
				<div className="RoomOverview-hexagon"><Hexagon currentLanguageId={currentLanguageId}/></div>
				<div className="RoomOverview-datatilsynetLogo"></div>
			</div>

			{/* Link to availability statement */}
			<div className="RoomOverview-asLink">
				<a href="https://www.was.digst.dk/datadysten-dk" target="_blank" rel="noreferrer">
					{getUiText(currentLanguageId, 'generalUiTexts', 'availabilityStatement')}
				</a>
			</div>

			{appConfig.env !== 'production' && 
			<div className="TrophyBtn" onClick={() => {goToPage('trophyRoom', 1);}}>
					TrophyRoom
			</div>}
			{appConfig.env !== 'production' && 
			<div className="ResetBtn" onClick={() => {resetPlayerData();}}>
					Reset
			</div>}
		</div>
	);
};

RoomOverview.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
	userCanSelectRoom: PropTypes.bool.isRequired,
	rooms: PropTypes.array.isRequired,
	goToPage: PropTypes.func.isRequired,
	resetPlayerData: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
};

export default RoomOverview;