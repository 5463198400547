import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {roomPoints} from 'data/points-data';
import {checkIfRoomStreakUnlocked} from 'helpers/points-helper';
import Challenge from './challenge';
import {quizPoints, singlePairPoints, multiplePairsPoints, sortPoints, pointsToPhones} from 'data/points-data';
import {updateRoomCompletion} from 'helpers/room-data-helper';

class ChallengeController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			challengeCompleted: false,
			streakUnlocked: false,
			roomStreakUnlocked: false,
			showChallengeCompletedPopup: false,
			showStreakPopup: false,
			feedback: null,
			challengeIndex: 0,
			maxPossiblePoints: 0,
			phoneIndex: 2,
		};
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount() {
		this.prepareChallenge();
	}

	prepareChallenge = () => {
		/* Get index of first un-completed challenge */
		let challengeIndex = 0;
		let endLoop = false;
		let maxPoints = 0;

		let pointPercentage = 0;
		if (this.props.playerData.hasOwnProperty('roomCompletion')) {
			const roomCompletions = JSON.parse(JSON.stringify(this.props.playerData.roomCompletion));
			let completionId = roomCompletions.findIndex((roomCompletion) => { 
				return roomCompletion.roomId === this.props.roomId;
			});
			
			pointPercentage = completionId === -1 ? 0 : this.props.playerData.roomCompletion[completionId].percentage; 
		}

		this.updatePhoneIndex(pointPercentage);

		this.props.roomChallengesData.forEach((challengeData, index) => {
			// Calculate max possible points
			switch (challengeData.type) {
			case 'quiz':
				maxPoints += quizPoints.basePoints;
				break;
			case 'sort':
				maxPoints += sortPoints.pointValues[0];
				break;
			case 'pairs':
				let numberOfPairs = challengeData.columnA.filter((card) => {
					return card.hasOwnProperty('correctConnectionId');
				}).length;

				if (numberOfPairs === 1) {
					// singlepair
					maxPoints += singlePairPoints.pointValues[0];
				} else {
					// multiplepairs
					maxPoints += multiplePairsPoints.pointValues[0];
				}
				break;
			default: 
				break;
			}

			if (this.props.playerData.hasOwnProperty('challenges') && this.props.playerData.challenges.length > 0) {
				if (endLoop) return;
				let playerChallengeIndex = this.props.playerData.challenges.findIndex((challenge) => {
					return challenge.id === challengeData.id;
				});
				/* First un-competed challenge */
				if (playerChallengeIndex < 0 || !this.props.playerData.challenges[playerChallengeIndex].completed) {
					challengeIndex = index;
					endLoop = true;					
				}

				/* End of loop, all challenges are completed */
				if (index === this.props.roomChallengesData.length - 1) {
					challengeIndex = this.props.roomChallengesData.length - 1;
				}
			}
		});

		/* Update state, finish loading */
		this.setState({isLoading: false, challengeIndex, maxPossiblePoints: maxPoints});
	}

	componentDidUpdate = (previous) => {
		if (previous.roomId !== this.props.roomId) {
			this.prepareChallenge();
		}
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
	}

	/**
	 * Navigate to specific challenge in current room
	 * @param {number} challengeIndex
	 */
	goToChallenge = (challengeIndex) => {
		this.setState({
			challengeIndex: challengeIndex, 
			challengeCompleted: false,
			showChallengeCompletedPopup: false, 
			showStreakPopup: false,
			feedback: null
		});
		window.scrollTo(0, 0);
	}

	/**
	 * Navigate to the next challenge in current room
	 */
	goToNextChallenge = () => {
		if (this.state.challengeIndex < this.props.roomChallengesData.length - 1) {
			this.goToChallenge(this.state.challengeIndex + 1);
		}
	}

	/**
	 * Show / hide streak popup
	 * @param {bool} showStreakPopup
	 */
	toggleStreakPopup = (
		showStreakPopup, challengeCompleted = false, 
		isLastChallengeInRoom = false, 
		roomStreakUnlocked = false
	) => {
		this.setState({showStreakPopup: showStreakPopup}, () => {
			if (challengeCompleted && isLastChallengeInRoom) {
				this.props.handleRoomComplete(roomStreakUnlocked);
			}
		});
	}

	/**
	 * Finds the corresponding phone index based on the given point percentage number
	 * @param {number} pointPercentage number between 0-100 representing the percentage of gained points.
	 */
	updatePhoneIndex = (pointPercentage) => {
		let phoneIndex = 2;

		for (let i = 0; i < pointsToPhones.pointPercentages.length; i++) {
			if (pointPercentage >= pointsToPhones.pointPercentages[i]) {
				phoneIndex = pointsToPhones.phoneIndex[i];
				break;
			};
		}

		this.setState({phoneIndex: phoneIndex});
	}

	/**
	 * Update challenge data
	 * @param {*} challengeId 
	 * @param {*} points 
	 */
	updateChallengeData = (challengeId, points) => {
		/* Update challenge data */
		let playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
		let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
			return challenge.id === challengeId;
		});
		playerChallenges[playerChallengeIndex].points = points;

		/* Update point data */
		const roomCompletions = updateRoomCompletion(
			this.state.maxPossiblePoints,
			points,
			this.props.playerData, 
			false, 
			this.props.roomId
		);
		let completionId = roomCompletions.findIndex((roomCompletion) => { 
			return roomCompletion.roomId === this.props.roomId;
		});
		
		const pointPercentage = completionId === -1 ? 0 : roomCompletions[completionId].percentage; 
		this.updatePhoneIndex(pointPercentage);

		/* Update player data  */
		this.props.updatePlayerData({
			challenges: playerChallenges,
			roomCompletion: roomCompletions
		});
	}

	/**
	 * Complete challenge
	 * @param {string} challengeId
	 * @param {number} points
	 * @param {object} feedback
	 * @param {bool} streakUnlocked
	 */
	completeChallenge = (challengeId, points, feedback, streakUnlocked = false) => {
		let challengeCompleted = true; 

		/* Check if last challenge in room */
		let isLastChallengeInRoom = (this.state.challengeIndex >= this.props.roomChallengesData.length - 1);
		
		let roomStreakUnlocked = false;
		if (appConfig.useStreaks) {
			roomStreakUnlocked = (isLastChallengeInRoom 
				? checkIfRoomStreakUnlocked(this.props.roomChallengesData, this.props.playerData) 
				: false
			);
		}

		/* Update challenge data */
		let playerChallenges = JSON.parse(JSON.stringify(this.props.playerData.challenges));
		let playerChallengeIndex = playerChallenges.findIndex((challenge) => {
			return challenge.id === challengeId;
		});
		playerChallenges[playerChallengeIndex].completed = true;
		playerChallenges[playerChallengeIndex].points = points + (roomStreakUnlocked ? roomPoints.points : 0);

		/* Show streak popup / challenge completed (w/o feedback) / room completed popup */
		let showStreakPopup = streakUnlocked;
		let showChallengeCompletedPopup = (!isLastChallengeInRoom || feedback !== null);
		this.timeout = setTimeout(() => {
			this.setState({
				challengeCompleted, 
				showChallengeCompletedPopup, 
				showStreakPopup, 
				feedback, 
				streakUnlocked, 
				roomStreakUnlocked});
		}, 500);

		const roomCompletions = updateRoomCompletion(
			this.state.maxPossiblePoints,
			points,
			this.props.playerData, 
			isLastChallengeInRoom, 
			this.props.roomId,
			true
		);
		let completionId = roomCompletions.findIndex((roomCompletion) => { 
			return roomCompletion.roomId === this.props.roomId;
		});
		
		const pointPercentage = completionId === -1 ? 0 : roomCompletions[completionId].percentage; 
		this.updatePhoneIndex(pointPercentage);

		if (isLastChallengeInRoom && !showStreakPopup && feedback === null) {
			/* Show room completed popup */
			this.timeout = setTimeout(() => {
				this.props.handleRoomComplete(roomStreakUnlocked);
			}, 1000);
		}
		
		/* Update player data  */
		return this.props.updatePlayerData({
			challenges: playerChallenges,
			roomCompletion: roomCompletions
		}).then((response) => {
			if (response.status === 'ok' && !isLastChallengeInRoom) {
				this.timeout = setTimeout(() => {
					this.goToNextChallenge();
				}, 500);
			}
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		if (this.state.isLoading) return null;

		/* Get status of each challenge (ready / completed / failed) */
		let roomChallengesData = JSON.parse(JSON.stringify(this.props.roomChallengesData));
		roomChallengesData.forEach((challengeData) => {
			let status = 'ready';
			if (
				this.props.playerData.hasOwnProperty('challenges') &&
				this.props.playerData.challenges.some((challenge) => {return challenge.id === challengeData.id;})
			) {
				let playerChallengeData = this.props.playerData.challenges.filter((challenge) => {
					return challenge.id === challengeData.id;
				})[0];
				if (playerChallengeData.completed) {
					if (playerChallengeData.points > 0) {
						status = 'completed';
					} else {
						status = 'failed';
					}
				}
			}
			challengeData.status = status;
		});

		let isLastChallengeInRoom = (this.state.challengeIndex >= roomChallengesData.length - 1);

		return (
			<Challenge 
				challengeCompleted={this.state.challengeCompleted}
				isLastChallengeInRoom={isLastChallengeInRoom}
				roomStreakUnlocked={this.state.roomStreakUnlocked}
				showStreakPopup={this.state.showStreakPopup}
				roomId={this.props.roomId}
				rooms={this.props.rooms}
				challengeIndex={this.state.challengeIndex}
				roomChallengesData={roomChallengesData}
				playerData={this.props.playerData}
				goToPage={this.props.goToPage}
				goToChallenge={this.goToChallenge}
				toggleStreakPopup={this.toggleStreakPopup}
				completeChallenge={this.completeChallenge}
				updatePlayerData={this.props.updatePlayerData}
				updateChallengeData={this.updateChallengeData}
				phoneIndex={this.state.phoneIndex}
				currentLanguageId={this.props.currentLanguageId}
				setCurrentLanguageId={this.props.setCurrentLanguageId}
			/>
		);
	}
}

ChallengeController.propTypes = {
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	roomChallengesData: PropTypes.array.isRequired,
	rooms: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleRoomComplete: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default ChallengeController;