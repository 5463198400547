import React from 'react';
import PropTypes from 'prop-types';
import './framed-box.scss';

const FramedBox = ({type, children}) => {
	return (
		<div className={'FramedBox' + (type ? ' FramedBox--' + type : '')}>{children}</div>
	);
};

FramedBox.defaultProps = {
	type: null
};

FramedBox.propTypes = {
	type: PropTypes.string,
	children: PropTypes.object.isRequired
};

export default FramedBox;