import React from 'react';
import CookieConsent from './cookie-consent';
import PropTypes from 'prop-types';

class CookieConsentController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showCookieConsent: false,
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {

	}

	/**
	 * Opens consent dialog
	 */
	openConsent = () => {
		this.setState({showCookieConsent: true});
	}

	/**
	 * Accepts or declines cookies depending on the given boolean
	 * @param {bool} cookiesAccepted 
	 */
	handleClosePopup = (cookiesAccepted) => {
		this.props.setCookieConsent(cookiesAccepted).then((response) => {
			if (response.status === 'ok') {
				this.setState({
					showCookieConsent: false,
				});
			}
		});
	}

	/* Render component */
	render = () => {
		return <CookieConsent 
			currentLanguageId = {this.props.currentLanguageId}
			handleClosePopup = {this.handleClosePopup}
			showCookieConsent = {this.state.showCookieConsent}
			openConsent = {this.openConsent}
			cookieConsentState = {this.props.cookieConsentState}
			triggerFirstTimeCookieConsent = {this.props.triggerFirstTimeCookieConsent}
		/>;
	}
}

CookieConsentController.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
	cookieConsentState: PropTypes.bool.isRequired,
	setCookieConsent: PropTypes.func.isRequired,
	triggerFirstTimeCookieConsent: PropTypes.bool.isRequired,
};

export default CookieConsentController;