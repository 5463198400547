import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import ChallengeController from 'components/challenges/challenge-controller';
import './room.scss';

const Room = (props) => {
	let {
		rooms,
		roomId, 
		roomChallengesData, 
		playerData, 
		goToPage, 
		updatePlayerData, 
		handleRoomComplete,
		currentRoomPoints,
		currentLanguageId,
		setCurrentLanguageId,
	} = props;

	if (roomChallengesData.length === 0) {
		let noChallengesText = JSON.parse(
			JSON.stringify(
				getUiText(currentLanguageId, 'challengeUiTexts', 'noChallenges')
			)
		);
		noChallengesText = noChallengesText.replace(/%roomId%/g, roomId);

		return (
			<div className="Room">
				<div className="Room-body">{noChallengesText}</div>
			</div>
		);
	}

	return (
		<div className="Room">
			{/* Challenge */}
			<ChallengeController 
				currentRoomPoints= {currentRoomPoints}
				roomId={roomId}
				roomChallengesData={roomChallengesData}
				playerData={playerData}
				rooms={rooms}
				goToPage={goToPage}
				updatePlayerData={updatePlayerData}
				handleRoomComplete={handleRoomComplete}
				currentLanguageId={currentLanguageId}
				setCurrentLanguageId={setCurrentLanguageId}
			/>
		</div>
	);
};

Room.propTypes = {
	rooms: PropTypes.array.isRequired,
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	roomChallengesData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleRoomComplete: PropTypes.func.isRequired,
	currentRoomPoints: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default Room;