import {roomsData} from 'data/rooms-data';
import {sortArrayByProperty} from './array-helper';

/**
 * Check if player has completed all rooms
 * @param {array} challengesData 
 * @param {object} playerData 
 */
export function areAllRoomsCompleted(challengesData, playerData) {
	let allRoomsCompleted = false;

	let numberOfCompletedRooms = 0;
	roomsData.forEach((room) => {
		let roomChallengesData = challengesData.filter((challenge) => {return challenge.roomId === room.id;});
		let roomIsCompleted = isRoomCompleted(roomChallengesData, playerData);
		if (roomIsCompleted) numberOfCompletedRooms = numberOfCompletedRooms + 1;			
	});

	if (numberOfCompletedRooms === roomsData.length) allRoomsCompleted = true;

	return allRoomsCompleted;
}

/**
 * Check if player has completed room
 * @param {array} roomChallengesData 
 * @param {object} playerData 
 */
export function isRoomCompleted(roomChallengesData, playerData) {
	let roomIsCompleted = false;

	if (roomChallengesData.length <= 0) {
		/* No challenges in room */
		roomIsCompleted = true;
	} else {
		/* Check last challenge of room */
		let roomChallengesDataSorted = sortArrayByProperty(roomChallengesData, 'challengeNumber', 'ASC');
		let lastChallengeId = roomChallengesDataSorted[roomChallengesData.length - 1].id;
		let playerChallengeIndex = playerData.challenges.findIndex((challenge) => {
			return challenge.id === lastChallengeId;
		});
		roomIsCompleted = 
			playerChallengeIndex >= 0 &&
			playerData.challenges[playerChallengeIndex].hasOwnProperty('completed') &&
			playerData.challenges[playerChallengeIndex].completed === true
		;
	}

	return roomIsCompleted;
}