import React from 'react';
import PropTypes from 'prop-types';
import './game-logo.scss';

const GameLogo = ({currentLanguageId}) => {
	return (
		<div className={'GameLogo'}>
			<div className={'GameLogo-logo ' + currentLanguageId}/>
		</div>
	);
};

GameLogo.propTypes = {
	currentLanguageId: PropTypes.string.isRequired,
};

export default GameLogo;