import { roomsData } from 'data/rooms-data';

/**
 * Syncs room list with player progress and returns the resulting list.
 * @param {array} challengesData 
 * @param {object} playerData 
 * @returns A list of rooms data.
 */
export function getPlayerSyncedRoomData(challengesData, playerData) {
	let rooms = JSON.parse(JSON.stringify(roomsData));

	/* Sync with player progress */
	rooms.forEach((room) => {
		let roomChallengesData = challengesData.filter((challengeData) => {
			return (
				challengeData.roomId === room.id &&
				challengeData.challengeNumber && challengeData.challengeNumber >= 0
			);
		});
		if (
			roomChallengesData.length > 0 && 
			playerData.hasOwnProperty('challenges') && playerData.challenges.length > 0
		) {
			let completedChallenges = 0;
			roomChallengesData.forEach((challenge) => {
				let playerChallengeIndex = playerData.challenges.findIndex((challengeData) => {
					return challengeData.id === challenge.id;
				});
				if (playerChallengeIndex >= 0 && playerData.challenges[playerChallengeIndex].completed) {
					completedChallenges = completedChallenges + 1;
				}
			});
		}
	});

	return rooms;
}

/**
 * Gets current room completion data from playerdata.
 * If there are none for current room it adds it to the list, else it updates the current one in the list.
 * If the list does not exist in player data, it is created with the current room completion data.
 * Only updates total points if updateTotal is set to true.
 * @param {number} maxPoints
 * @param {number} points 
 * @param {object} playerData
 * @param {bool} isRoomCompleted
 * @param {number} roomId
 * @returns Updated list of roomCompletion for player data.
 */
export function updateRoomCompletion (maxPoints, points, playerData, isRoomCompleted, roomId, updateTotal = false) {
	let roomCompletions = [];
	if (playerData.hasOwnProperty('roomCompletion')) {
		roomCompletions = JSON.parse(JSON.stringify(playerData.roomCompletion));
		let roomCompletionIndex = roomCompletions.findIndex((roomCompletion) => {
			return roomCompletion.roomId === roomId;
		});
		if (roomCompletionIndex !== -1) {
			// If the room has already been completed before, we want to retain this fact
			const wasRoomCompleted = playerData.roomCompletion[roomCompletionIndex].completed;
			roomCompletions[roomCompletionIndex].completed = wasRoomCompleted ? wasRoomCompleted : isRoomCompleted;
			const totalPoints = roomCompletions[roomCompletionIndex].pointTotal + points;
			roomCompletions[roomCompletionIndex].percentage = parseInt(totalPoints / maxPoints * 100);
			if (updateTotal) {
				roomCompletions[roomCompletionIndex].pointTotal = totalPoints;
			}
		} else {
			const currentRoomCompletion = {
				roomId: roomId,
				pointTotal: updateTotal ? points : 0,
				percentage: parseInt(points / maxPoints * 100),
				completed: isRoomCompleted
			};
			roomCompletions.push(currentRoomCompletion);
		}
	} else {
		roomCompletions = [{
			roomId: roomId,
			pointTotal: updateTotal ? points : 0,
			percentage: parseInt(points / maxPoints * 100),
			completed: isRoomCompleted
		}];
	}

	return roomCompletions;
}

/**
 * Resets the current room completion data from playerdata.
 * If there are none for current room it adds it to the list, else it resets the current one in the list.
 * If the list does not exist in player data, it is created.
 * @param {object} playerData
 * @param {bool} isRoomCompleted
 * @param {number} roomId
 * @returns Reset list of roomCompletion for player data.
 */
export function resetRoomCompletion (playerData, isRoomCompleted, roomId) {
	let roomCompletions = [];
	if (playerData.hasOwnProperty('roomCompletion')) {
		roomCompletions = JSON.parse(JSON.stringify(playerData.roomCompletion));
		let roomCompletionIndex = roomCompletions.findIndex((roomCompletion) => {
			return roomCompletion.roomId === roomId;
		});
		if (roomCompletionIndex !== -1) {
			const currentPercentage = roomCompletions[roomCompletionIndex].percentage;
			let bestPercentage = currentPercentage;
			if (roomCompletions[roomCompletionIndex].hasOwnProperty('bestScore')) {
				const previousBest = roomCompletions[roomCompletionIndex].bestScore;
				bestPercentage = currentPercentage > previousBest ? currentPercentage : previousBest;
			}
			roomCompletions[roomCompletionIndex].bestScore = parseInt(bestPercentage);
			roomCompletions[roomCompletionIndex].percentage = 0;
			roomCompletions[roomCompletionIndex].pointTotal = 0;
		} else {
			const currentRoomCompletion = {
				roomId: roomId,
				pointTotal: 0,
				percentage: 0, 
				completed: isRoomCompleted
			};
			roomCompletions.push(currentRoomCompletion);
		}
	} else {
		roomCompletions = [{
			roomId: roomId,
			pointTotal: 0,
			percentage: 0, 
			completed: isRoomCompleted
		}];
	}
	return roomCompletions;
}

/**
 * Clears the player data belonging to a specific room. 
 * @param {number} roomId 
 * @param {object} playerData 
 */
export function resetRoomChallengeData(roomId, playerData) {
	let challenges = [];
	if (playerData.hasOwnProperty('challenges')) {
		playerData.challenges.forEach((challenge) => {
			if (challenge.roomId !== roomId) {
				challenges.push(challenge);
			}
		});
	}

	return challenges;
}

/**
 * Finds a trophy id corresponding to the given playerdata and roomId
 * @param {number} roomId 
 * @param {object} playerData 
 * @returns number between 0-4, 0 being no trophy won.
 */
export function getTrophyId(roomId, playerData) {
	let trophyId = 0;

	let pointPercentage = 0;
	let isCompleted = false;
	if (playerData.hasOwnProperty('roomCompletion')) {
		const roomCompletions = JSON.parse(JSON.stringify(playerData.roomCompletion));
		let completionId = roomCompletions.findIndex((roomCompletion) => { 
			return roomCompletion.roomId === roomId;
		});

		if (completionId !== -1) {
			let percentage = roomCompletions[completionId].percentage;
			if (roomCompletions[completionId].hasOwnProperty('bestScore')) {
				const previousBest = roomCompletions[completionId].bestScore;
				percentage = percentage > previousBest ? percentage : previousBest;
			}
			isCompleted = roomCompletions[completionId].completed;
			pointPercentage = completionId === -1 ? 0 : percentage; 
		}
	}

	if (!isCompleted) {
		trophyId = 0;
	} else if (pointPercentage >= 90) {
		trophyId = 3;
	} else if (pointPercentage >= 65) {
		trophyId = 2;
	} else if (pointPercentage >= 0) {
		trophyId = 1;
	}

	return trophyId;
}

/**
 * Gets whether the corresponding room to the given roomId, is completed
 * @param {number} roomId 
 * @param {object} playerData
 * @returns true if the room for the given roomId is completed
 */
export function getIsRoomCompleted(playerData, roomId) {
	let isRoomCompleted = false;

	if (playerData.hasOwnProperty('roomCompletion')) {
		const roomCompletions = JSON.parse(JSON.stringify(playerData.roomCompletion));
		let roomCompletionIndex = roomCompletions.findIndex((roomCompletion) => {
			return roomCompletion.roomId === roomId;
		});
		if (roomCompletionIndex !== -1) {
			isRoomCompleted = roomCompletions[roomCompletionIndex].completed;
		}
	}
	return isRoomCompleted;
}