import React from 'react';
import PropTypes from 'prop-types';
import { getUiText } from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import Popup from 'components/layout/popup';
import './quiz.scss';
import { getTranslation } from 'helpers/language-helper';

const Quiz = (props) => {
	let {
		isPaused, 
		playerHasSeenClueWarning,
		showConfirmGetCluePopup, 
		showImagePopup,
		quizData, 
		toggleCluePopup, 
		toggleImagePopup,
		handleGetClue,
		handleSelectAnswer,
		challengeIndex,
		currentLanguageId
	} = props;

	const numberOfAnswers = quizData.answers.length;
	let numberOfCorrectAnswers = quizData.answers.filter((answer) => {return answer.isCorrect === true;}).length;
	let numberOfRevealedClues = (quizData.clues 
		? quizData.clues.filter((clue) => {return clue.isRevealed;}).length
		: 0
	);

	return (
		<div className={'Quiz Quiz--' + quizData.id + (isPaused ? ' Quiz--paused' : '')}>

			{/* Quiz header */}
			<div id="QuizHeader" className="Quiz-header">
				<div className="Quiz-headerContent">
					<div className='Quiz-questionHeader'>
						<div className='Quiz-questionHeaderText'>
							{getUiText(currentLanguageId, 'challengeUiTexts', 'question')}
							{challengeIndex}
						</div>
					</div>
					{/* Question & image thumbnail*/}
					<div className={'Quiz-question'}>					
						{renderMarkdown(getTranslation(quizData.question.text, currentLanguageId))}		
					</div>
					{/* Clues */}
					{quizData.clues && 
					<div className="Quiz-clues">
						{numberOfRevealedClues > 0 && <div className="Quiz-clueInfo">
							<span>
								{getUiText(currentLanguageId, 'challengeUiTexts', 'hintTitle')}:
							</span>
						</div>}
						{quizData.clues.map((clue, index) => {
							return (clue.isRevealed 
								? <div key={index} className="Quiz-clueText">
									{renderMarkdown(getTranslation(clue.text, currentLanguageId))}
								</div> 
								: null
							);
						})}
						{(!quizData.completed && quizData.clues.some((clue) => {return !clue.isRevealed;})) && 
							<div 
								className="Quiz-getClueBtn"
								onClick={() => {
									if (playerHasSeenClueWarning) {
										handleGetClue();
									} else {
										toggleCluePopup(true);
									}
								}}
								dangerouslySetInnerHTML={{__html: (numberOfRevealedClues > 0 
									? 
									getUiText(currentLanguageId, 'challengeUiTexts', 'getAnotherHint')
									: 
									getUiText(currentLanguageId, 'challengeUiTexts', 'getAHint')
								)}}
							/>
						}
					</div>
					}
				</div>
			</div>

			{/* Quiz body */}
			<div id="QuizBody" className="Quiz-body">
				{/* Image */}
				{(quizData.question.image && !quizData.question.showImageInPopup) &&
					<div className={'Quiz-image Quiz-image--' + quizData.id} />
				}
				{/* Instructions */}
				{quizData.question.showNumberOfAnswers &&
					<div className="Quiz-instructions">
						{numberOfCorrectAnswers > 1 ?
							getUiText(currentLanguageId, 'challengeUiTexts', 'chooseMore')
							:
							getUiText(currentLanguageId, 'challengeUiTexts', 'chooseOne')
						}
					</div>
				}
				{/* Answers */}
				<div className={numberOfAnswers > 4 ? 'Quiz-answers Quiz-answers--flexWrap' : 'Quiz-answers'}>
					{quizData.answers.map((answer, index) => {
						let className = 'Quiz-answer';
						if (answer.isSelected) {
							if (!quizData.completed) {
								className = className + ' Quiz-answer--' 
									+ (answer.isCorrect ? 'animateCorrect' : 'animateWrong');
							} else {
								className = className + ' Quiz-answer--' + (answer.isCorrect ? 'correct' : 'wrong');
							}
						}
						if (numberOfAnswers > 4) {
							className = className + ' Quiz-answer--twoColumns';
						}
						return (
							<div 
								key={index} 
								className={className}
								onClick={() => {if (!isPaused && !answer.isSelected) handleSelectAnswer(answer.id);}}
							>{renderMarkdown(getTranslation(answer.text, currentLanguageId))}</div>
						);
					})}
				</div>
			</div>

			<Popup 
				showPopup={showConfirmGetCluePopup}
				type="getClue" 
				title={
					getUiText(currentLanguageId, 'challengeUiTexts', 'getCluePopup').title
				}
				text={
					getUiText(currentLanguageId, 'challengeUiTexts', 'getCluePopup').text
				}
				buttons={[
					{
						text: getUiText(currentLanguageId, 'challengeUiTexts', 'getCluePopup').buttons.ok, 
						action: handleGetClue, 
						params: []
					},
					{
						text: getUiText(currentLanguageId, 'challengeUiTexts', 'getCluePopup').buttons.cancel, 
						action: toggleCluePopup, 
						params: [false]
					} 
				]}
			/>

			<Popup 
				showPopup={showImagePopup}
				frameText={true}
				type="quizImage" 
				title={null}
				text={null}
				textObj={null}
				image={quizData.question.popupImage}
				onClose={() => {toggleImagePopup(false);}}
			/>
		</div>
	);
};

Quiz.propTypes = {
	isPaused: PropTypes.bool.isRequired,
	playerHasSeenClueWarning: PropTypes.bool.isRequired,
	showConfirmGetCluePopup: PropTypes.bool.isRequired,
	showImagePopup: PropTypes.bool.isRequired,
	quizData: PropTypes.object.isRequired,
	toggleCluePopup: PropTypes.func.isRequired,
	toggleImagePopup: PropTypes.func.isRequired,
	handleGetClue: PropTypes.func.isRequired,
	handleSelectAnswer: PropTypes.func.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
};

export default Quiz;