import React from 'react';
import PropTypes from 'prop-types';
import { conceptsData } from 'data/rooms/concepts-data';
import { rightsData } from 'data/rooms/rights-data';
import { advancedData } from 'data/rooms/advanced-data';
import './trophy-room.scss';
import Phone from 'components/layout/phone';
import Hexagon from 'components/layout/hexagon';
import WarningButton from 'components/warning-button/warning-button';
import { getPlayerSyncedRoomData, getIsRoomCompleted, getTrophyId } from 'helpers/room-data-helper';
import { getUiText } from 'helpers/text-helper';
import { getTranslation } from 'helpers/language-helper';
import LanguageButton from 'components/language-button/language-button';

const TrophyRoom = ({resetRoomData, goToPage, playerData, roomId, currentLanguageId, setCurrentLanguageId}) => {	
	/* Get challenges of room */
	let challenges = 0;
	switch (roomId) {
	case 1:
		challenges = [...conceptsData];
		break;
	case 2:
		challenges = [...rightsData];
		break;
	case 3:
		challenges = [...advancedData];
		break;
	default:
		throw new Error('Unknown room id');
	}

	const rooms = getPlayerSyncedRoomData(challenges, playerData);

	/* Calculating challenge results based on player data */
	let trophyId = getTrophyId(roomId, playerData);
	let hexagonText = getUiText(currentLanguageId, 'trophyRoomTexts', 'bronze');
	let confettiArray = [];

	if (trophyId === 2) hexagonText = getUiText(currentLanguageId, 'trophyRoomTexts', 'silver');
	else if (trophyId === 3) {
		hexagonText = getUiText(currentLanguageId, 'trophyRoomTexts', 'gold');
		for (let i = 0; i < 150; i++) {
			confettiArray.push({
				key: i, 
				positionX: Math.random(), 
				randomDelay: Math.random(),
				randomDuration: Math.random(),
				randomZ: parseInt(Math.random() * 99),
				randomR: parseInt(Math.random() * 255),
				randomG: parseInt(Math.random() * 255),
				randomB: parseInt(Math.random() * 255)
			});
		}
	};

	return (
		<div className='TrophyRoom'>
			<div className='TrophyRoom-languageButton'>
				<LanguageButton
					currentLanguageId={currentLanguageId} 
					setCurrentLanguageId={setCurrentLanguageId} 
				/>
			</div>
			{/* TrophyRoom sidebar */}
			<div className='TrophyRoom-sidebar'>
				<div className={'TrophyRoom-sidebarLogo ' + currentLanguageId}
					onClick={() => {goToPage('roomOverview');}}/>
				{rooms.length > 0 &&
				<div className="TrophyRoom-sidebarRoomButtons">
					{rooms.map((room) => {
						return (
							<div key={room.id}>
								<WarningButton 
									warningText={
										getUiText(currentLanguageId, 'generalUiTexts', 'play')
										+ ' ' 
										+ getTranslation(room.prerequisite, currentLanguageId)  
										+ ' ' 
										+ getUiText(currentLanguageId, 'generalUiTexts', 'first')
									}
									roomId={room.id}
									roomName={getTranslation(room.name, currentLanguageId)}
									roomIsOpen={room.prerequisiteId ? 
										getIsRoomCompleted(playerData, room.prerequisiteId) : true}
									goToPage={goToPage}
									isCurrentRoom={room.id === roomId}
								/>
							</div>
						);
					})}	
				</div>}
				<Phone id={6}/>
			</div>
			{/* TrophyRoom falling confetti */}
			<div className='TrophyRoom-confettiWrapper'>
				{confettiArray.map((confetti) => {
					return (
						<div key={confetti.key} 
							className='TrophyRoom-fallingConfetti'
							style={{
								left: confetti.positionX * 98 + '%', 
								animationDuration: confetti.randomDuration * 5 + 2 + 's',
								animationDelay: confetti.randomDelay * 5 + 's', 
								zIndex: confetti.randomZ,
							}}>
							<div 
								className='TrophyRoom-rotatingConfetti'
								style={{
									animationDelay: confetti.randomDelay * 1 + 's',
									animationDuration: confetti.randomDuration + 0.3 + 's',
									backgroundColor: 'rgb(' + 
									confetti.randomR + ',' + 
									confetti.randomG + ',' + 
									confetti.randomB + ')'
								}}
							/>
						</div>
					);
				})}
			</div>
			<div className='TrophyRoom-wrapper'>
				<div className='TrophyRoom-headerWrapper'>
					<div className="TrophyRoom-header">
						<div className='TrophyRoom-headerStatus'>
							<div className='TrophyRoom-line'/>
							{challenges.map((challenge, index) => {
								let className = 'TrophyRoom-challengeStatus';

								if (index !== 0) {
									className = className + ' TrophyRoom-challengeStatus--notFirst';
								}
								return (
									<div
										key={index}
										className={className}/>
								);
							})}
						</div>
						<div className='TrophyRoom-counter'>{challenges.length}/{challenges.length}
						</div>
					</div>
				</div>
				<div className='TrophyRoom-bodyWrapper'>
					<div className='TrophyRoom-confetti'>
						<div className={'TrophyRoom-trophy-' + trophyId}/>
					</div>
				</div>
				<div className='TrophyRoom-buttons'>
					<div className='TrophyRoom-button' onClick={() => {resetRoomData();}}>
						{getUiText(currentLanguageId, 'generalUiTexts', 'tryAgain')}
					</div>
					<div className='TrophyRoom-button' onClick={() => {goToPage('roomOverview');}}>
						{getUiText(currentLanguageId, 'generalUiTexts', 'continue')}
					</div>
				</div>
			</div>
			<div className='TrophyRoom-logoWrapper'>
				<div className='TrophyRoom-hexagon'>
					<Hexagon id={0} textId={trophyId} currentLanguageId={currentLanguageId}>{hexagonText}</Hexagon>
				</div>
				<div className='TrophyRoom-datatilsynetLogo'/>
			</div>
		</div>
	);
};

TrophyRoom.propTypes = {
	setCurrentLanguageId: PropTypes.func.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	resetRoomData: PropTypes.func.isRequired,
	goToPage: PropTypes.func.isRequired,
	playerData: PropTypes.object.isRequired,
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
};

export default TrophyRoom;