const roomsData = [
	{
		id: 1, 
		name: {
			da: 'Begreber',
			en: 'Concepts'
		},
		prerequisite: '',
		intro: 'Velkommen til Staudekvarteret. Stauder er urter der lever i flere år. Plantens overjordiske dele visner som regel bort om vinteren, mens de underjordiske dele overlever.<br /><br />Gå hen til hvor markøren peger, og find salvie. Tryk så klar.',
		map: {
			pinPos: [6, -7],
			pinPath: 'M171.16,63.84c-1.36-1.6-2.72-3.22-4.11-4.81a108.43,108.43,0,0,1-9.7-12.1,28,28,0,0,1-4.41-10.49,18.49,18.49,0,1,1,36.53-5.73c.74,4.75-.47,9.1-2.67,13.26a60.14,60.14,0,0,1-7.7,10.7C176.49,57.73,173.84,60.75,171.16,63.84Zm-9.82-30.48a9.87,9.87,0,1,0,10-9.75A9.88,9.88,0,0,0,161.34,33.36Z',
			circle: {cx: '171.19', cy: '33.31', r: '11.5'},
			numberPath: 'M172.45,29.63c0-.9,0-1.72.05-2.14a.26.26,0,0,0-.25-.2c-.13,0-.81.34-1.6.58a20,20,0,0,1-2.3.5.49.49,0,0,0,0,.63l.82.11c.79.11.86.38.86,1.57v5.81c0,1.44-.11,1.62-1,1.7l-.74.05c-.16.11-.14.58,0,.63.65,0,1.73-.06,2.92-.06s2.2,0,2.84.06c.18-.06.2-.52,0-.63l-.74-.06c-.88-.07-1-.25-1-1.69Z'
		},
		layout: {x: 9.72, y: 9.05, width: 110, height: 67},
		layoutThumb: {type: 'rect', x: 4.25, y: 4.5, width: 11, height: 9},

	},
	{
		id: 2,
		name: {
			da: 'Rettigheder',
			en: 'Rights'
		}, 
		prerequisite: {
			da: 'Begreber',
			en: 'Concepts'
		},
		prerequisiteId: 1,
		intro: 'Velkommen til Stenhøjen. Mange af planterne her kommer fra bjergområder og har derfor tilpasninger til at overleve i et ekstremt miljø.<br /><br />Find mammuttræet der hvor markøren peger. Tryk så klar.',
		map: {
			pinPos: [-16, -18], 
			pinPath: 'M193.16,232.84c-1.36-1.6-2.72-3.22-4.11-4.81a108.43,108.43,0,0,1-9.7-12.1,28,28,0,0,1-4.41-10.49,18.49,18.49,0,1,1,36.53-5.73c.74,4.75-.47,9.1-2.67,13.26a60.14,60.14,0,0,1-7.7,10.7C198.49,226.73,195.84,229.75,193.16,232.84Zm-9.82-30.48a9.87,9.87,0,1,0,10-9.75A9.88,9.88,0,0,0,183.34,202.36Z',
			circle: {cx: '193.19', cy: '202.31', r: '11.5'},
			numberPath: 'M192.49,206c-.25,0-.34-.11-.34-.22s.16-.4.95-1.13l.86-.81c2.16-2,2.72-3,2.72-4.45a3.11,3.11,0,0,0-3.14-3.07h-.26a4.42,4.42,0,0,0-4.14,2.81.37.37,0,0,0,.44.31.34.34,0,0,0,.15-.06,2.73,2.73,0,0,1,2.32-1.65,2,2,0,0,1,2,2,.91.91,0,0,0,0,.17c0,1.92-1.12,3.44-2.36,4.8-1.08,1.19-2.11,2.15-2.79,2.78a.32.32,0,0,0,.18.4,17.74,17.74,0,0,1,2-.07h2.87c1.15,0,1.8,0,2.52.06a8.34,8.34,0,0,0,.88-2.79c-.07-.24-.49-.24-.61-.11-.53.95-.74,1-2.06,1Z'
		},
		layout: {x: 226.72, y: 9.05, width: 82, height: 179},
		layoutThumb: {type: 'rect', x: 23.25, y: 4.5, width: 10, height: 18},

	},
	{
		id: 3, 
		name: {
			da: 'Avanceret',
			en: 'Advanced'
		}, 
		prerequisite: {
			da: 'Rettigheder',
			en: 'Rights'
		},
		prerequisiteId: 2,
		intro: 'Velkommen til Granbakken. Området har især fokus på planters udvikling, gifte og brugen af planter som lægemidler.<br /><br />Find tempeltræerne. De er hvor markøren peger nær den hvide bro. Tryk så klar.',
		map: {
			pinPos: [2, -9], 
			pinPath: 'M131.16,307.84c-1.36-1.6-2.72-3.22-4.11-4.81a108.43,108.43,0,0,1-9.7-12.1,28,28,0,0,1-4.41-10.49,18.49,18.49,0,1,1,36.53-5.73c.74,4.75-.47,9.1-2.67,13.26a60.14,60.14,0,0,1-7.7,10.7C136.49,301.73,133.84,304.75,131.16,307.84Zm-9.82-30.48a9.87,9.87,0,1,0,10-9.75A9.88,9.88,0,0,0,121.34,277.36Z',
			circle: {cx: '131.19', cy: '277.31', r: '11.5'},
			numberPath: 'M130.34,272.43a1.4,1.4,0,0,1,1.48,1.31,2.11,2.11,0,0,1-.76,1.62,12.14,12.14,0,0,1-2.9,1.46.6.6,0,0,0,.28.81.34.34,0,0,0,.14.05,2.57,2.57,0,0,1,1.78-.75c1.28,0,2.1,1.15,2.1,2.75a3,3,0,0,1-2.41,3.24.89.89,0,0,1-.77-.41c-.16-.24-.56-1-1.21-1a1,1,0,0,0-1.07.92.17.17,0,0,0,0,.07c0,.92,1,1.28,2,1.28a7.4,7.4,0,0,0,4.15-1.44,4.56,4.56,0,0,0,2.08-3.67,2.81,2.81,0,0,0-2.81-2.8h-.22c-.23,0-.36-.14-.16-.23,2-.85,2.27-1.58,2.27-2.32,0-1.21-1.1-2.07-2.86-2.07a4.09,4.09,0,0,0-3.77,2.28.4.4,0,0,0,.45.35h.05A3,3,0,0,1,130.34,272.43Z'
		},
		layout: {x: 242.72, y: 201.05, width: 67, height: 167},
		layoutThumb: {type: 'rect', x: 28.25, y: 23.5, width: 5, height: 16},

	},
];

export {
	roomsData
};