const quizPoints = {
	minPoints: 1,
	basePoints: 5,
	basePointsWithClues: 8,
	minusPointsPerWrongAnswer: 1,
	minusPointsPerClues: 2
};

const singlePairPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 2],
	pointValues: [5, 3, 2]	
};

const multiplePairsPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 2, 3],
	pointValues: [8, 6, 4, 2]
};

const sortPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const orderPoints = {
	minPoints: 1,
	pointLimits: [0, 1, 3, 5],
	pointValues: [8, 6, 4, 2]
};

const streakPoints = {
	streakMarker: 5,
	streakPoints: 5
};

const roomPoints = {
	points: 5
};

const pointsToPhones = {
	pointPercentages: [75, 50, 25, 0],
	phoneIndex: [5, 4, 3, 2]
}

export {
	quizPoints,
	singlePairPoints,
	multiplePairsPoints,
	sortPoints,
	orderPoints,
	streakPoints,
	roomPoints,
	pointsToPhones
};