import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import { getUiText } from 'helpers/text-helper';
import {streakPoints} from 'data/points-data';
import Popup from 'components/layout/popup';
import QuizController from './quiz/quiz-controller';
import PairsController from './pairs/pairs-controller';
import SortController from './sort/sort-controller';
import OrderController from './order/order-controller';
import './challenge.scss';
import Phone from 'components/layout/phone';
import Hexagon from 'components/layout/hexagon';
import WarningButton from 'components/warning-button/warning-button';
import { getIsRoomCompleted } from 'helpers/room-data-helper';
import { getTranslation } from 'helpers/language-helper';
import LanguageButton from 'components/language-button/language-button';

const Challenge = (props) => {
	let {
		roomStreakUnlocked,
		challengeCompleted,
		isLastChallengeInRoom,
		showStreakPopup,
		roomId,
		rooms,
		challengeIndex, 
		roomChallengesData, 
		playerData, 
		goToPage,
		toggleStreakPopup,
		completeChallenge, 
		updatePlayerData,
		updateChallengeData,
		goToChallenge,
		phoneIndex,
		currentLanguageId,
		setCurrentLanguageId
	} = props;

	let Component = <div>Unknown challenge: {roomChallengesData[challengeIndex].type}</div>;
	if (roomChallengesData[challengeIndex].type === 'quiz') Component = QuizController;
	if (roomChallengesData[challengeIndex].type === 'pairs') Component = PairsController;
	if (roomChallengesData[challengeIndex].type === 'sort') Component = SortController;
	if (roomChallengesData[challengeIndex].type === 'order') Component = OrderController;

	let streakPopupText = '';
	let streakPopupPoints = 0;
	if (showStreakPopup) {
		let correctAnswers = streakPoints.streakMarker;
		streakPopupPoints = streakPoints.streakPoints;
		streakPopupText = 
			JSON.parse(JSON.stringify(
				getUiText(currentLanguageId, 'challengeUiTexts', 'streakPopup').text))
				.replace(/%answers%/g, correctAnswers);
	}

	return (
		<div className={'Challenge Challenge--' + roomChallengesData[challengeIndex].type}>
			<div className='Challenge-languageButton'>
				<LanguageButton  
					currentLanguageId={currentLanguageId} 
					setCurrentLanguageId={setCurrentLanguageId} 
				/>
			</div>
			{/* Challenge sidebar */}
			<div className='Challenge-sidebar'> 
				<div className={'Challenge-sidebarLogo ' + currentLanguageId} 
					onClick={() => {goToPage('roomOverview');}}
				/>
				{rooms.length > 0 &&
				<div className="Challenge-sidebarRoomButtons">
					{rooms.map((room) => {
						return (
							<div key={room.id}>
								<WarningButton 
									warningText={
										getUiText(currentLanguageId, 'generalUiTexts', 'play')
										+ ' ' 
										+ getTranslation(room.prerequisite, currentLanguageId) 
										+ ' ' 
										+ getUiText(currentLanguageId, 'generalUiTexts', 'first')
									}
									roomId={room.id}
									roomName={getTranslation(room.name, currentLanguageId)}
									roomIsOpen={room.prerequisiteId ? 
										getIsRoomCompleted(playerData, room.prerequisiteId) : true}
									goToPage={goToPage}
									isCurrentRoom={room.id === roomId}
								/>
							</div>
						);
					})}	
				</div>}
				<Phone id={phoneIndex}/>
			</div>
  
			<div className='Challenge-wrapper'>
				{/* Challenge header */}
				<div className="Challenge-header">
					<div className='Challenge-headerStatus'>
						<div className='Challenge-line'/>
						{roomChallengesData.map((challenge, index) => {
							let className = 'Challenge-challengeStatus';
							if (index === challengeIndex) {
								className = className + ' Challenge-challengeStatus--selected';
							} else {
								className = className + ' Challenge-challengeStatus--' + challenge.status;
							}

							if (index !== 0) {
								className = className + ' Challenge-challengeStatus--notFirst';
							}
							let canNavigateTo = false;
							if (appConfig.env === 'development' || appConfig.env === 'test') canNavigateTo = true;
							return (
								<div
									key={index}
									className={className}
									onClick={()=>{if (canNavigateTo) goToChallenge(index);}}/>
							);
						})}
					</div>
					<div className='Challenge-counter'>{challengeIndex + 1}/{roomChallengesData.length}</div>
				</div>

				{/* Challenge body */}
				<div className="Challenge-body">
					<Component 
						roomId={roomId}
						challengeData={roomChallengesData[challengeIndex]}
						playerData={playerData}
						toggleStreakPopup={toggleStreakPopup}
						completeChallenge={completeChallenge}
						updatePlayerData={updatePlayerData}
						updateChallengeData={updateChallengeData}
						challengeIndex={challengeIndex + 1}
						currentLanguageId={currentLanguageId}
					/>
				</div>
			</div>

			<div className='Challenge-logoWrapper'>
				<div className='Challenge-hexagon'>
					<Hexagon
						id={roomChallengesData[challengeIndex].hexagonImageId}
						currentLanguageId={currentLanguageId}/>
				</div>
				<div className='Challenge-datatilsynetLogo'/>
			</div>
			

			{/* Streak popup */}
			<Popup 
				showPopup={showStreakPopup}
				type="streak" 
				title={getUiText(currentLanguageId, 'challengeUiTexts', 'streakPopup').title}
				text={streakPopupText}
				points={streakPopupPoints}
				buttons={[{
					text: getUiText(currentLanguageId, 'challengeUiTexts', 'streakPopup').buttons.ok,
					action: toggleStreakPopup,
					params: [false, challengeCompleted, isLastChallengeInRoom, roomStreakUnlocked]
				}]}
			/>
		</div>
	);
};

Challenge.propTypes = {
	roomStreakUnlocked: PropTypes.bool.isRequired,
	challengeCompleted: PropTypes.bool.isRequired,
	isLastChallengeInRoom: PropTypes.bool.isRequired,
	showStreakPopup: PropTypes.bool.isRequired,
	roomId: PropTypes.oneOfType([
		PropTypes.string.isRequired,
		PropTypes.number.isRequired,
	]),
	rooms: PropTypes.array.isRequired,
	challengeIndex: PropTypes.number.isRequired,
	roomChallengesData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	goToPage: PropTypes.func.isRequired,
	toggleStreakPopup: PropTypes.func.isRequired,
	completeChallenge: PropTypes.func.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateChallengeData: PropTypes.func.isRequired,
	goToChallenge: PropTypes.func.isRequired,
	phoneIndex: PropTypes.number.isRequired,
	currentLanguageId: PropTypes.string.isRequired,
	setCurrentLanguageId: PropTypes.func.isRequired,
};

export default Challenge;